:root {
    --primary-100: #d5e6fb;
    --primary-500: #369eea;
    --primary-600: #297bb6;
    --primary-800: #164363;
    --bg-primary: #131c2f;
    --bg-primary-100: #2f4063;
    --bg-primary-200: #222e46;
    --gray-800: #626878;
    --gray-400: #7b8794;
    --gray-200: #a9afb7;
    --gray-100: #cdcdcd;
    --gray-1: #333333;
    --gold: #f9b700;
    --red-100: #eb5757;
    --red-200: #b93939;
    --red-500: #7a2727;
    --green-100: #27ae60;
    --green-500: #155831;
    --orange-100: #e69705;
    --orange-500: #6b4600;
    --pink-300: #ff66c4;
    --pink-800: #4d2b79;

    --text-subdued: rgba(255, 255, 255, 0.65);
    --text-very-subdued: rgba(255, 255, 255, 0.4);

    --input-bg: var(--bg-primary-200);
    --input-color: #fff;

    --body-padding: 0;
    --section-padding: 10px;
}

.theme-light {
    --input-bg: #fff;
    --input-color: var(--gray-800);
}

body {
    background-color: var(--bg-primary);
    color: #fff;
    margin: 0;
    font-family: Mulish;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 1.2;

    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;

    &.scroll-lock {
        overflow: hidden;
        position: fixed;
        width: 100%;
        top: var(--scroll-top, 0);
    }

    * {
        box-sizing: border-box;
    }

    .App {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .modal-open {
            pointer-events: none;
        }

        > *:not(.modal, .sidebar) {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }

        main {
            padding: var(--body-padding);
            width: 100%;
        }

        .main-overlay {
            position: fixed;

            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}

// ::-webkit-scrollbar {
//     width: 12px;
//     height: 12px;
// }

// ::-webkit-scrollbar-track {
//     background: var(--bg-primary-200);
// }

// ::-webkit-scrollbar-thumb {
//     background: var(--gray-800);
// }

.text-accent {
    color: var(--primary-500);
}

.text-white {
    color: white;
}

.text-gray-1 {
    color: var(--gray-1);
}

.text-secondary {
    color: var(--gray-400);
}

.text-very-bold {
    font-weight: 800;
}

.text-center {
    text-align: center;
}

.text-gold {
    color: var(--gold);
}

.text-subdued {
    color: var(--text-subdued);
}

.text-very-subdued {
    color: var(--text-very-subdued);
}

.text-caps {
    text-transform: capitalize;
}

.opacity-25 {
    opacity: 0.25;
}

.vertical-middle {
    vertical-align: middle;
}

h2,
.h2 {
    font-size: 18px;
}

.h3 {
    font-size: 1.17em;
    margin: 1em 0;
    font-weight: bold;
}

.h6 {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 10px;
}

.a,
a {
    color: var(--primary-500);
    // text-decoration: underline;
    text-decoration: none;
    cursor: pointer;
}

.aria-hidden {
    width: 1px;
    height: 1px;
    opacity: 0;
    margin: 0;
    position: absolute;
    padding: 0;
    pointer-events: none;
    visibility: hidden;
}

button,
[role='button'] {
    cursor: pointer;
    user-select: none;
}

small,
.small {
    font-size: 12px;
    line-height: 15px;
    // display: inline-block;
}

.strong {
    font-weight: bold;
}

small.input-info {
    color: var(--text-subdued);
    line-height: 1.2;
    display: block;
    // line-height: 1;
}

label + small.input-info {
    color: var(--text-subdued);
    line-height: 1.2;
    display: block;
    margin-bottom: 10px;
}

input[type='file'] {
    display: block;
    padding: 30px;
    border: 1px solid var(--gray-400);
    width: 100%;
    font-family: inherit;
}

label {
    font-weight: 800;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 7px;
    display: block;
    letter-spacing: 1.5px;
    line-height: 1;

    &.required::after {
        content: '(Required)';
        color: var(--text-subdued);
        margin-left: 5px;
        display: inline-block;
    }

    &.optional::after {
        content: '(Optional)';
        color: var(--text-subdued);
        margin-left: 5px;
        display: inline-block;
    }

    &.has-button {
        display: flex;
        align-items: flex-end;
    }
}

pre {
    font-family: inherit;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    white-space: pre-line;

    &.condensed {
        line-height: 1.1;
        margin: 0;
    }

    p {
        margin: 0;
    }
}

.m-0 {
    margin: 0 !important;
}

// Component: MultipleInputs, MultiplePhoneInputs
.multiple-inputs,
.multiple-phone-inputs {
    > div > div > [type='button'] {
        flex-basis: 45px;
    }
    .input-container {
        position: relative;
        border-radius: 8px;
        overflow: visible;

        .popover {
            .popover-childen {
                width: auto;
            }

            > div:first-child {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                width: 95px;
                overflow: hidden;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            .button {
                box-shadow: none;
                height: 100%;
                width: 100%;
            }

            [role='button'] {
                background-color: var(--primary-100);
                margin: 0;
                padding: 20px 20px;
                border-bottom: 1px solid #bfcfe1;
                cursor: pointer;
                font-size: 15px;
                line-height: 1;
                text-align: left;
                letter-spacing: 1.25px;
                font-family: Mulish;
                font-weight: 700;
                -webkit-user-select: none;
                user-select: none;
                display: flex;
                align-items: center;
                color: var(--gray-1);
            }

            // + div > .text-input input {
            //     padding-left: 100px;
            // }

            + div > .icon + .text-input input {
                padding-left: 130px;
            }
        }

        > div > .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 105px;
            z-index: 1;
        }

        .text-input {
            input {
                padding-right: 60px;
            }
        }
    }

    &.multiple-phone-inputs {
        .input-container {
            .react-international-phone-input-container {
                // flex: 1;

                [role='combobox'] {
                    height: 100%;
                }

                .react-international-phone-country-selector {
                    position: absolute;
                    left: 100px;
                    top: 1px;
                    bottom: 1px;
                    width: 44px;
                }

                .react-international-phone-input {
                    padding: 12px;
                    padding-left: 145px;
                    padding-right: 60px;
                    border-radius: 8px;
                    width: 100%;
                    color: var(--input-color);
                    border: none;
                    margin-bottom: 21px;
                    font-weight: 600;
                    font-family: Mulish;
                    font-size: 16px;
                    line-height: 24px;
                    border: 0.5px solid #7b8794;
                    background-color: var(--input-bg);
                    appearance: none;
                    margin: 0;
                    height: unset;
                    border-top-left-radius: 8;
                    border-bottom-left-radius: 8;
                }

                .react-international-phone-country-selector-button {
                    border-radius: 0;
                    background-color: var(--bg-primary-200);
                    border: none;
                }

                .react-international-phone-input:focus {
                    outline: none;
                    border-color: var(--primary-500);
                }
            }
        }
    }
}

// Component: SectionContainer
.section-container {
    padding: calc(var(--section-padding) / 2) var(--section-padding);

    > .section-card {
        background-color: var(--bg-primary-200);
        border-radius: 8px;
        overflow: hidden;

        > h2 {
            font-size: 14px;
        }

        > h2,
        > h3 {
            padding: 5px 10px;
            margin: 0;
            background-color: var(--gray-400);
        }

        > div {
            padding: 10px;
        }

        .resource-list,
        .resource-list-new {
            .list-header,
            .list-footer {
                background-color: var(--bg-primary-200);
            }
        }
    }

    &:empty {
        padding: 0;
    }

    .images-container {
        img {
            max-width: 100%;
            height: auto;
            display: block;
        }
    }
}

// Component: Terms
.terms {
    margin-bottom: 100px;
}

// Component: ExpandingButton
.expanding-button {
    position: relative;

    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
    }
}

// Component: Sidebar
aside.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    border-right: 1px solid var(--bg-primary-100);
    padding: 10px;

    max-width: min(400px, calc(100vw / 2 - 500px / 2 - 40px));
    background-color: var(--bg-primary);

    text-transform: uppercase;
    z-index: 12;

    a {
        color: inherit;
        display: block;
        padding: 5px 10px;
    }

    .toggle summary {
        padding: 5px 10px;

        > .toggle-header {
            justify-content: space-between;
        }
    }

    .toggle > .toggle-body-wrapper > .toggle-body {
        padding-top: 0;
        padding-bottom: 0;
    }

    @media (max-width: 900px) {
        display: none;
    }
}

// Component: MainFooter
.main-footer {
    position: absolute;
    bottom: 0;

    .ad {
        position: fixed;
        bottom: 0;
    }

    .ad + .nav-bar {
        bottom: 60px;
    }
}

// Component: Image
.image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    object-fit: contain;
}

// Component: Tag
.tag {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding: 2px 10px;
    border-radius: 100px;
    color: #fff;
    display: inline-block;
    margin-right: 5px;
    border: 1px solid transparent;
    text-transform: capitalize;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: center;
    position: relative;

    > a[href] {
        color: inherit;
    }

    &.outline {
        background-color: transparent;
        border-color: var(--gray-400);
        color: var(--gray-400);
    }

    &.success {
        background-color: var(--green-100);
        border-color: var(--green-100);
        color: #fff;

        &.outline {
            background-color: transparent;
            color: var(--green-100);
        }
    }

    &.alert {
        background-color: var(--red-100);
        border-color: var(--red-100);
        color: #fff;

        &.outline {
            background-color: transparent;
            color: var(--red-100);
        }
    }

    &.warning {
        background-color: #b3a808;
        border-color: #b3a808;
        color: #fff;

        &.outline {
            background-color: transparent;
            color: #b3a808;
        }
    }

    &.primary {
        background-color: var(--primary-500);
        border-color: var(--primary-500);
        color: #fff;

        &.outline {
            background-color: transparent;
            color: var(--primary-500);
        }
    }

    &.gold {
        background-color: var(--gold);
        border-color: var(--gold);
        color: #fff;

        &.outline {
            background-color: transparent;
            color: var(--gold);
        }
    }

    &.gray {
        background-color: var(--gray-800);
        border-color: var(--gray-800);
        color: #fff;

        &.outline {
            background-color: transparent;
            color: var(--gray-800);
        }
    }

    &.white {
        background-color: white;
        border-color: white;

        &.outline {
            background-color: transparent;
            color: white;
        }
    }

    &.square {
        border-radius: 0;
    }

    &.m-0 {
        margin: 0;
    }

    &.with-reverse-margin {
        margin: 0;
        margin-left: 5px;
        margin-top: 5px;
    }

    &.with-options {
        // z-index: 11;
        position: relative;
        cursor: pointer;

        .actions-btn {
            padding: 0;
            flex-direction: row;
            font-family: inherit;
            text-align: left;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            cursor: pointer;
            text-transform: inherit;
        }
    }

    > img[role='button'] {
        margin-left: 5px;
        width: 12px;
        height: 12px;
        cursor: pointer;
    }

    > .tag-options {
        z-index: 12;
        position: absolute;
        top: 100%;
        left: -20px;
        right: -20px;
        // background-color: #fff;
        // border-radius: 16px;
        // overflow: hidden;
        transform: translateY(5px);
        box-shadow: 0px 0px 10px #000000;

        > * {
            cursor: pointer;
            position: relative;
            z-index: 13;
            // width: 100px;
            padding-top: 15px;
            padding-bottom: 15px;
            display: block;
            margin: 0;
            border-radius: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .ellipses {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 0;
        border: none;
        gap: 1px;

        .ellipses-dot {
            width: 3px;
            height: 3px;
            border-radius: 5px;
            background-color: #fff;
            display: block;
            margin: 0;
            margin-left: 4px;
        }
    }

    &.outline .ellipses-dot {
        background-color: var(--gray-400);
    }
}

// Component: FormGroupTitle
.form-group-title {
    margin: 0;
    font-size: 18px;
    margin: 30px 0 30px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--gray-400);
    }
}

// Component: Popover
.popover {
    z-index: var(--z-index);
    // align-self: start;

    .popover-childen {
        background-color: black;
        position: fixed;
        top: var(--top);
        left: var(--left);
        z-index: 25;
        width: var(--max-width);
        max-height: var(--max-height, unset);
        overflow: auto;

        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        &.open {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;

            + .popover-overlay {
                opacity: 0.5;
                visibility: visible;
                pointer-events: auto;
            }
        }

        &::after {
            content: '';
            position: fixed;
            top: var(--after-top, unset);
            left: var(--after-left, unset);
            width: 0;
            height: 0;
            border-style: solid;
            // Arrow pointing right
            border-width: 10px 0 10px 10px;
            border-color: transparent transparent transparent
                var(--arrow-color, var(--primary-100));
            border-width: 10px 10px 10px 10px;
            transform: var(--arrow-transform);
        }
    }

    .popover-overlay {
        position: fixed;
        z-index: 24;
        background-color: black;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
    }

    // &.hovered {
    //     opacity: 0.5;
    // }
    &.hovered .popover-childen {
        width: unset;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;

        &:after {
            display: none;
        }

        + .popover-overlay {
            display: none;
        }

        .action-item {
            padding: 4px;
            font-size: 12px;
        }
    }
}

// Component: Spinner
.spinner {
    pointer-events: none;
    width: 2.5em;
    height: 2.5em;
    border: 0.4em solid transparent;
    border-radius: 50%;
    animation: loadingSpin 1s linear infinite;
    margin: 0 auto;
    border-color: var(--gray-800);
    border-top-color: var(--primary-500);

    &.size-tiny {
        width: 1.3em;
        height: 1.3em;
        border-width: 0.2em;
    }

    &.margin-top {
        margin-top: 50px;
    }

    &.margin-bottom {
        margin-bottom: 20px;
    }
}

// Component: ErrorMessage
.error-message {
    padding: 5px 10px;
    background-color: var(--red-200);
    color: #fff;
    border-radius: 7px;
    font-weight: bold;
    margin-bottom: 15px;

    a {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        text-decoration: underline;
    }

    &.error-message-section {
        margin-left: 10px;
        margin-right: 10px;
    }

    .close-btn {
        background-color: transparent;
        padding: 10px;
        border: none;
        color: #fff;
        padding: 0;
        margin: 0;
        appearance: none;
    }
}

// Component: PermissionsSection
.permissions-section {
    margin-bottom: 20px;

    h3 {
        display: block;
        padding: 5px 10px;
        margin-bottom: 0;
        background-color: var(--gray-400);
    }

    > div {
        padding: 10px;
        background-color: var(--bg-primary-200);

        h4 {
            margin: 0;
            margin-bottom: 10px;
        }

        table {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
            margin-bottom: 20px;

            th {
                text-transform: capitalize;
            }

            th,
            td {
                min-width: 120px;
                max-width: 120px;
                overflow: hidden;
                padding: 5px;
                white-space: break-spaces;
                line-height: 1;
                height: 32px;

                &:first-child {
                    border-right: 1px solid var(--gray-200);
                    position: sticky;
                    left: 0;
                    top: 0;
                    background-color: var(--bg-primary-200);
                    z-index: 2;
                }

                .checkbox {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;

                    &::before {
                        background-color: var(--bg-primary-200);
                    }

                    label {
                        // display: none;
                    }
                }

                button {
                    appearance: none;
                    background: none;
                    color: #fff;
                    border: none;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    img {
                        margin-right: 2px;
                    }
                }
            }

            td:not(:first-child) > div {
                display: flex;
                justify-content: center;
            }
        }

        ul {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            list-style: none;
            padding: 0;
            margin: 0;
            margin-bottom: 10px;
            flex-wrap: wrap;
            gap: 10px;

            li {
                background-color: var(--bg-primary-100);
                padding: 5px;
                margin: 0;
            }
        }
    }
}

// Component: FilesRemoveInput
.files-remove-input {
    //
    min-height: 50px;

    .grid > div {
        background-color: var(--bg-primary-100);
        padding: 5px 0;
        position: relative;

        > button {
            position: absolute;
            right: 0;
            top: 0;
            appearance: none;
        }
    }

    &.has-error {
        border: 1px solid var(--red-200);
        border-radius: 8px;
        overflow: hidden;
    }
}

// Component: HtmlInput
.html-input {
    position: relative;
    border-radius: 8px;

    &:focus-within {
        box-shadow: 0 0 0 2px var(--primary-500);
    }

    .controls {
        border: 0.5px solid #7b8794;
        border-bottom: none;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 1px;
        // overflow: hidden;
        background-color: var(--input-bg);

        position: sticky;
        top: 0;
        left: 0;
        right: 0;

        > * > .button,
        > * > .browser-file-input-button,
        > * > .browser-file-input-button .button,
        > * > .popover > * > .button {
            height: 32px;
            box-shadow: none;
            border: 1px solid var(--input-bg);

            &.button-outline:hover {
                color: var(--primary-500);
            }
        }

        > * > .browser-file-input-button {
            border: none;
        }

        .color-picker-container {
            position: relative;

            > div:first-child {
                cursor: pointer;
                position: absolute;
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--primary-500);
                top: 1px;
                left: 1px;
                pointer-events: none;
                font-weight: bold;
                font-size: 15px;
            }
        }

        .bg-color-picker-container {
            position: relative;

            > div:first-child {
                cursor: pointer;
                position: absolute;
                width: calc(100% - 6px);
                height: calc(100% - 6px);
                display: flex;
                align-items: center;
                justify-content: center;
                top: 3px;
                left: 3px;
                pointer-events: none;
                overflow: hidden;
                border: 1px solid white;
                background-color: var(--primary-500);
                border-radius: 0;

                &::after {
                    position: absolute;
                    content: '';
                    left: -6px;
                    top: -6px;
                    width: calc(100% + 12px);
                    height: calc(100% + 12px);
                    background-color: var(--bg-input);
                    border-radius: 0;
                }
            }
        }

        input[type='color'] {
            height: 32px;
            width: 32px;
            padding: 0;
            background-color: var(--primary-500);
            margin: 0;
            border: none;
            appearance: none;
            border: 1px solid var(--input-bg);
        }

        > :first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            overflow: hidden;
        }

        .browser-file-input .input-container .paste-handle {
            display: none;
        }
    }

    > div.html-editable,
    .text-input textarea {
        padding: 12px;
        border-radius: 8px;
        width: 100%;
        border: none;
        margin-bottom: 0;
        // font-weight: 600;
        font-family: Mulish;
        font-size: 16px;
        line-height: 24px;
        border: 0.5px solid #7b8794;
        appearance: none;
        background-color: var(--input-bg);
        color: var(--input-color);

        &:focus {
            outline: none;
            border-color: var(--primary-500);
        }

        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
        height: 40vh;
        overflow: auto;

        &.hide-editor {
            width: 1px;
            height: 1px;
            opacity: 0;
            margin: 0;
            position: absolute;
            padding: 0;
        }

        h1 {
            text-align: unset;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .editor-item {
        background-color: var(--primary-100);
        margin: 0;
        padding: 10px 10px;
        border-bottom: 1px solid #bfcfe1;
        cursor: pointer;
        font-size: 15px;
        line-height: 1;
        text-align: left;
        letter-spacing: 1.25px;
        // text-transform: capitalize;
        font-family: Mulish;
        font-weight: 700;
        user-select: none;
        display: flex;
        align-items: center;
        color: var(--gray-800);

        &:last-child {
            border-color: transparent;
        }
    }

    .editor-link {
        background-color: var(--bg-primary);
        padding: 5px;

        .button {
            margin-bottom: 0;
        }
    }

    .html-editable h1,
    .editor-h1 > div {
        font-size: 24px;
        font-weight: bold;
    }

    .html-editable h2,
    .editor-h2 > div {
        font-size: 1.3em;
        font-weight: bold;
    }

    .html-editable h3,
    .editor-h3 > div {
        font-size: 1.17em;
        font-weight: bold;
    }

    .html-editable h4,
    .editor-h4 > div {
        font-weight: bold;
    }

    .html-editable h5,
    .editor-h5 > div {
        font-size: 0.83em;
        font-weight: bold;
    }

    .html-editable h6,
    .editor-h6 > div {
        font-size: 0.67em;
        font-weight: bold;
    }

    .editor-p > div {
        font-weight: normal;
    }

    .html-editable blockquote,
    .editor-blockquote > div {
        font-style: italic;
        font-weight: normal;
        padding: 10px;
        border-left: 5px solid gray;
        margin: 0;
    }

    .img-loading {
        animation: pulse 2s infinite ease-in-out;
        color: transparent;
        background: rgba(255, 255, 255, 0.3);
        user-select: none;
        box-sizing: border-box;

        max-width: 100%;
        height: auto;

        @keyframes pulse {
            0% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.4;
            }
            100% {
                opacity: 0.2;
            }
        }
    }

    &.has-error {
        .controls {
            border-color: var(--red-200);
        }

        .html-editable,
        .text-input textarea {
            border-color: var(--red-200);
        }
    }
}

// Preformatted
.preformatted {
    img {
        max-width: 100%;
    }
}

// Component: TimesheetEntryForm
.timesheet-entry-form {
    > form {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr;
        gap: 0 10px;

        > div {
            // > :first-child {
            //     margin-bottom: 10px;
            //     grid-column: 1 / 2;
            // }

            // > :last-child {
            //     margin-bottom: 10px;
            //     grid-column: 2 / 3;
            // }

            // input {
            //     margin-bottom: 0;
            // }
        }

        details.toggle {
            // margin: 0 0 10px;
            grid-column: 1 / 3;
        }
    }

    &.timesheet-create-form {
        // padding: 0 0 0;

        .timesheet-project-input {
            grid-column: 1 / 3;
            margin-bottom: 20px;
        }

        .text-input + small.text-subdued {
            margin-top: -21px;
            display: block;
        }

        .live-search {
            grid-column: 1 / 3;
        }

        .button:not([title='Clear']) {
            grid-column: 1 / 3;
            margin-top: 20px;
        }

        .clear-btn {
            margin-bottom: 0;

            .button {
                grid-column: unset;
                margin-top: unset;
            }
        }

        button[type='submit']:not([title='Clear']) {
            margin-top: 0;
        }
    }
}

// Component: TimesheetDaysList
.timesheet-days-list {
    overflow: auto;
    margin-bottom: 20px;

    ol {
        display: flex;
        flex-wrap: nowrap;
        padding: 0;
        margin: 0;
        list-style: none;
        padding-left: 16px;
        padding-right: 16px;

        li {
            flex: 1 0 75px;
            text-align: center;
            padding: 5px;
            border: 1px solid var(--gray-800);
            color: #fff;
            line-height: 1;
            cursor: pointer;
            margin-right: 10px;
            margin-bottom: 10px;

            span {
                display: block;
            }

            &.active {
                border-color: var(--primary-500);
                background-color: var(--primary-500);
            }

            // &.disabled {
            //     background-color: var(--gray-800);
            //     color: var(--gray-1);
            //     cursor: not-allowed;
            // }
        }
    }
}

// Component: LiveSearch
.live-search {
    margin-bottom: 21px;
    //
    position: relative;

    .text-input input {
        margin-bottom: 0;
    }

    + small {
        margin-top: -16px;
    }

    &.live-search-open input {
        border-radius: 8px 8px 0 0;
    }

    .live-error {
        color: rgb(192, 45, 45);
        font-weight: bold;
    }

    .live-empty {
        color: var(--gray-800);

        + [role='button'] {
            margin-top: 10px;
        }
    }

    .live-results {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        background-color: var(--input-bg);
        color: var(--input-color);
        border: 0.5px solid #7b8794;
        border-radius: 0 0 8px 8px;
        padding: 12px;
        max-height: 200px;
        overflow: auto;
        font-size: 16px;
        z-index: 12;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            + [role='button'] {
                margin-top: 10px;
            }

            li {
                padding: 10px 0;
                margin: 0;
                line-height: 1;

                &.live-result-clickable {
                    cursor: pointer;
                }
            }
        }
    }
}

// Component: InlineFormGroup
.inline-form-group {
    appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;

    legend {
        margin: 0;
        padding: 0;
        font-weight: 800;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 7px;
        display: block;
        letter-spacing: 1.5px;
        line-height: 1;
    }

    > div {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px;

        > * {
            flex: 1;
        }
    }
}

// Component: PdfViewer
.pdf-viewer {
    margin-bottom: 20px;

    iframe {
        width: 100%;
        height: 300px;
    }
}

// Component: HtmlEditor
.html-editor {
    margin-bottom: 20px;

    iframe,
    textarea {
        overflow-y: auto;
        height: 40vh;
        border: 1px solid var(--bg-primary-100);
        width: 100%;
        border-radius: 0 0 8px 8px;
        margin-bottom: 0;
    }

    iframe {
        background-color: #fff;
        color: #000;
    }

    .switch .button {
        margin-bottom: 0;
    }
}

// Component: ScrollableContent
.scrollable-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 40vh;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid var(--bg-primary-100);

    &.full-height {
        height: 100%;
    }
}

// Component: ImageSlider
.image-slider {
    .inner-slider {
        position: relative;

        .slider-content {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 10px;
            display: grid;
            grid-template-columns: 80% auto;
            align-content: space-between;

            > *:nth-child(2n) {
                text-align: right;
            }
        }

        .slider-image {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
        }

        > button {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            appearance: none;
            border: none;
            background: none;
            color: white;
            // color: var(--primary-500);
            cursor: pointer;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            // background-color: #fff;

            &:last-child {
                left: unset;
                right: 10px;
            }
        }

        width: 100%;
        height: 100%;
        padding-bottom: var(--padding-bottom, 100%);
        background-color: var(--gray-200);
    }

    ul {
        display: grid;
        list-style: none;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
        margin: 0;
        padding: 0;
        margin-top: 10px;

        li {
            background-color: var(--gray-200);
            padding-bottom: var(--padding-bottom, 100%);
            display: block;
            margin: 0;
            height: 100%;

            &.active {
                border: 2px solid var(--gray-1);
            }
        }
    }
}

// Component: CustomDetails
.custom-details {
    > div {
        max-height: var(--max-height, 100px);
        overflow: hidden;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            background: linear-gradient(rgba(0, 0, 0, 0), var(--bg-primary));
        }

        &.details-open {
            max-height: unset;

            &::after {
                display: none;
            }
        }
    }

    .button {
        margin-top: 10px;

        text-decoration: underline;
    }
}

// Component: DataForm
.data-form {
    > details {
        margin-bottom: 20px;
    }

    &.data-form-inline {
        display: grid;

        // Give me a grid layout where all the items with class .input-wrapper are on the first column, and the last item div is on the second column
        grid-template-columns: 1fr max-content;
        grid-template-rows: auto;
        column-gap: 10px;

        // gap: 10px;
        // align-items: flex-end;
        margin-bottom: 20px;

        > .input-wrapper {
            grid-column: 1 / 2;
            margin-bottom: 0;
        }

        > *:last-child {
            grid-row: 1 / 2;
            grid-column: 2 / 3;
            margin-bottom: 0;
            align-self: flex-end;
        }

        input {
            margin-bottom: 0;
        }

        .button {
            margin-bottom: 0;
            height: 50px;
        }
    }
}

// Component: NotifsListWidget
.notifs-list-widget {
    display: flex;
    flex-direction: column;
    // margin-bottom: 20px;
    min-height: 93px;

    .nav-controls {
        // align-self: normal;
        // display: flex;
        // align-items: center;

        h2 {
            flex: 1;
            margin: 0;
            font-size: 14px;

            margin-left: 10px;
        }
    }

    .curr-notif {
        flex: 1;
        border: 1px solid var(--bg-primary-100);
        padding: 10px;
        padding-right: 0;
        padding-top: 5px;
        padding-bottom: 5px;

        color: #fff;
        border-radius: 7px;
        font-weight: bold;
        width: 100%;

        details {
            margin: 0;

            summary {
                line-height: 1.2;
                display: block;
                margin-top: 5px;
                list-style: none;
                // justify-content: space-between;

                // > div {
                //     width: 100%;
                // }
            }
        }

        h4 {
            margin: 0;
            cursor: pointer;
            padding-top: 5px;
        }

        .notif-subtitle {
            font-size: 12px;
            font-weight: normal;
            line-height: 1.2;
        }

        .notif-date {
            opacity: 0.5;
            font-weight: normal;
            margin-right: 12px;
            margin-top: 5px;
        }

        .controls {
            margin-right: 12px;
        }
    }

    .prev-btn,
    .next-btn {
        appearance: none;
        border: none;
        background: transparent;
        font-family: inherit;
        cursor: pointer;
        color: #fff;
        font-size: 30px;
        padding: 2px 8px;

        &:disabled {
            opacity: 0.3;
        }

        img {
            display: block;
        }
    }

    .notifs-bell-container {
        position: relative;

        > span {
            color: white;
            font-size: 9px;
            line-height: 1;
            content: '';
            position: absolute;
            top: -2px;
            right: -4px;
            background-color: var(--red-200);
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            word-break: keep-all;
            font-weight: 400;
        }
    }
}

// Compontent: TranslationItem
.translation-item {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto auto;
    column-gap: 10px;
    align-items: start;

    label {
        grid-column: 1 / 3;
    }

    input,
    textarea {
        margin: 0;
        padding: 2px 12px;
    }

    textarea.long-text {
        height: 30vh;

        ~ small {
            white-space: pre-line;
        }
    }

    small {
        margin-top: 10px;
        opacity: 0.5;
    }
}

// Component: Glossary
.glossary {
    display: grid;
    grid-template-columns: 1fr auto;

    h3 {
        margin: 0;
        padding-top: 20px;
        margin-bottom: 10px;
    }

    .letter-index {
        ul {
            padding: 5px 0;
            display: flex;
            flex-direction: column;
            margin: 0;
            list-style: none;
            position: sticky;
            top: 0;
        }

        li {
            padding: 0;
            margin: 0;
            text-align: center;
            cursor: pointer;
            padding: 5px;
        }
    }
}
section .glossary .letter-index {
    // margin-right: -20px;
}

// Component: Menu
.menu {
    view-transition-name: menu;
    height: calc(100vh - 80px - 55px - 10px);
    overflow: auto;
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
    border-left: 1px solid var(--bg-primary-200);
    border-top: 1px solid var(--bg-primary-200);
    // Large shadow right
    box-shadow: -11px 0px 10px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--bg-primary);
}

// Component: InlineForm
.inline-form {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    column-gap: 10px;

    label {
        grid-column: 1 / 3;
    }

    input {
        margin: 0;
        padding: 2px 12px;
    }
}

// Component: InfoTip
.info-tip {
    background-color: var(--bg-primary-100);
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    line-height: 1;
}

// Component: InfoList
.info-list {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin: 0 0 10px;
            padding: 0;
            display: flex;
            align-items: center;

            > *:first-child {
                display: block;
                margin-right: 10px;
            }

            > div:first-child {
                width: 16px;
            }

            > *:last-child {
                display: block;
                line-height: 1.2;
            }

            .bold:last-child {
                font-weight: bold;
            }

            img[src^='https'] {
                border-radius: 50px;
            }
        }

        &.no-icons {
            li {
                > *:first-child {
                    display: none;
                }
            }
        }

        &.tight {
            li {
                margin-bottom: 2px;
            }
        }
    }
}

// Component: FormSteps
.form-steps {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 0 20px;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 16px;
        left: 21px;
        height: 1px;
        background-color: var(--gray-800);
        right: 21px;
        z-index: -1;
    }

    .form-steps-step {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #fff;
        text-align: center;
        line-height: 32px;
        color: var(--primary-500);

        &.complete {
            background-color: var(--primary-500);

            img {
                width: 24px;
                height: 24px;
                margin-top: 4px;
            }
        }

        &.active {
            color: #fff;
            background-color: var(--primary-500);
        }
    }
}

.registration-layout {
    header {
        text-align: center;
        padding-top: 24px;
        margin-bottom: 20px;

        h1 {
            line-height: 1;
            color: #fff;
        }

        .email {
            color: #fff;
        }

        p {
            font-size: 16px;
            line-height: 150%;
        }
    }
    footer .links {
        text-align: center;

        a,
        .button-link div {
            font-weight: 700;
            color: var(--primary-500);
        }

        &.links-dark a,
        &.links-dark .button-link div {
            font-weight: 700;
            color: unset;
        }
    }
}

.public-layout {
    .hero {
        padding-top: 120px;
        text-align: left;
        margin-bottom: 40px;

        h1 {
            line-height: 1;
        }
    }

    .logo-hero {
        padding-top: 120px;
        text-align: left;
        margin-bottom: 40px;

        img {
            display: block;
            margin: 0 auto;
        }

        h1 {
            line-height: 1;
        }

        p {
            font-size: 16px;
            line-height: 150%;
        }
    }

    .oauth-links-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    footer .links {
        text-align: center;
        font-weight: 700;

        a,
        .button-link {
            color: var(--primary-500);
        }

        &.links-dark a,
        &.links-dark .button-link {
            color: unset;
        }
    }
}

// Component: SignatureInput
.signature-input {
    > div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;

        label {
            margin: 0;
        }

        button {
            margin: 0;
        }
    }
    > div:last-child {
        margin-bottom: 20px;
        background-color: rgb(248, 248, 248);

        .sign-canvas {
            background-color: #fff;
            display: block;
            margin: 0 auto;
        }
    }

    &.has-error > div:last-child {
        border: 1px solid var(--red-200);
    }
}

// Component: MessageSection
.message-section {
    line-height: 1.2;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid white;

    * {
        color: #fff;
    }

    .message-body {
        font-weight: bold;

        .errors-list {
            padding-left: 20px;

            li {
                padding: 0;
            }
        }
    }

    &.warning {
        background-color: var(--orange-500);
        border-color: var(--orange-100);
    }

    &.success {
        background-color: var(--green-500);
        border-color: var(--green-100);
    }

    &.info {
        background-color: var(--primary-800);
        border-color: var(--primary-500);
    }

    &.alert {
        background-color: var(--red-500);
        border-color: var(--red-100);
    }

    a:not(.button) {
        text-decoration: underline;
    }

    ul,
    details {
        margin: 0;
    }

    header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        align-items: center;
        padding: 0;
        text-align: left;

        img + h3 {
            margin: 0;
            margin-left: 10px;
        }

        h3 {
            flex: 1;
            font-size: 12px;
            line-height: 1;
            font-weight: normal;
        }

        .close-btn {
            background-color: transparent;
            padding: 10px;
            border: none;
            color: #fff;
            align-self: flex-end;
            padding: 0;
            margin: 0;
            appearance: none;
        }

        + div:empty {
            margin-top: -10px;
        }
    }

    img {
        max-width: 100%;
    }
}

// Component: FinancialBillingList
.financial-billing-list {
    > div > div button {
        border: none;
        background-color: var(--bg-primary-100);
        color: #ffffff;
        padding: 5px 10px;
        border-radius: 50px;
        font-family: inherit;
        text-transform: capitalize;
        cursor: pointer;

        &.active {
            background-color: var(--primary-500);
        }
    }

    .financial-data {
        line-height: 1;

        > div {
            margin-bottom: 10px;
            display: grid;
            grid-template-columns: auto 130px;
            grid-template-rows: auto auto;
            grid-template-areas:
                'title amount'
                'subtitle amount';

            h3 {
                grid-area: title;
                margin: 0;
                padding-bottom: 3px;
            }

            small {
                grid-area: subtitle;
                margin: 0;
                color: var(--text-subdued);
            }

            p {
                grid-area: amount;
                margin: 0;
                text-align: right;
                font-weight: bold;
                font-size: 16px;
            }
        }
    }
}

// Component: AppFolderPicker
.app-folder-picker {
    list-style: none;
    padding: 0;
    margin: 20px 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        line-height: 1.1;
        font-size: 14px;
        cursor: pointer;
        text-transform: capitalize;

        &.folder-disabled {
            opacity: 0.5;
            cursor: initial;
        }

        img {
            margin-bottom: 10px;
        }
    }
}

// Component: AppFilePicker
.app-file-picker {
    .files-navigation {
        display: flex;
        align-items: center;

        div:first-child {
            flex: 1;

            img {
                cursor: pointer;
            }
        }

        > img {
            margin-right: 5px;
        }

        > span {
            font-size: 12px;
            text-transform: capitalize;
        }
    }

    .private-image {
        cursor: pointer;
    }

    // .file-selected {
    //     background-color: var(--primary-500);
    // }
}

// Component: PrivateFile
.private-file {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px auto;
    cursor: pointer;
    color: var(--bg-primary-200);

    &.is-image {
        background-color: transparent;
        padding: 0;
    }

    &.is-other {
        background-color: #fff;
        padding: 15px;
    }
}

// Component: BillingChart
.billing-chart {
    .chart-container {
        position: relative;
        display: flex;
        align-items: flex-start;
    }

    .months-container {
        display: flex;
        margin-top: 10px;
        width: 100%;
        overflow: auto;
        position: relative;
    }

    .lines {
        position: sticky;
        height: 115px;
        width: 50px;

        span {
            width: 100%;
            font-size: 12px;
            position: absolute;
            left: 0;
            border-bottom: 1px solid #fff;
            bottom: 35px;
            line-height: 1;

            &:nth-child(1) {
                border-color: #fff;
            }
            &:nth-child(2) {
                border-color: #369eea;
            }
            &:nth-child(3) {
                border-color: #e02b2b;
            }

            // &.second {
            //     display: none;
            //     bottom: 70px;
            // }
        }
    }

    .months {
        margin-bottom: 5px;
        display: inline-flex;

        .month {
            width: 70px;
            height: 100px;
            margin-right: 10px;
            cursor: pointer;

            .bars {
                display: flex;
                position: relative;
                height: 70px;
                margin: 0 5px 5px;

                span {
                    position: absolute;
                    bottom: 0;
                    width: 13px;
                    // height: 15px;
                    display: block;
                    height: 1px;
                    transition: height 1s ease-out;

                    &:nth-child(1) {
                        left: 0;
                        background-color: #fff;
                    }

                    &:nth-child(2) {
                        left: 50%;
                        transform: translate(-50%, 0);
                        background-color: #369eea;
                    }

                    &:nth-child(3) {
                        // left: 40px;
                        right: 0;
                        background-color: #e02b2b;
                    }
                }
            }

            .title {
                text-align: center;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                padding: 1px 5px;
                color: #fff;
                border-radius: 50px;
                height: 20px;
                text-transform: uppercase;

                &.selected {
                    background-color: #fff;
                    color: #000;
                }
            }
        }
    }

    .footer {
        border-top: 1px solid #369eea;
        margin-top: 15px;
        padding-top: 15px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        flex-flow: wrap;

        .index {
            display: flex;
            padding: 5px 0;
            flex: 100%;

            span {
                margin-right: 10px;
                line-height: 1;
                // position: relative;

                &:before {
                    content: '';
                    // position: absolute;
                    width: 11px;
                    height: 11px;
                    margin-right: 5px;
                    display: inline-block;
                }

                &.expenses:before {
                    background-color: #fff;
                }

                &.earned:before {
                    background-color: #369eea;
                }

                &.overdue:before {
                    background-color: #e02b2b;
                }
            }
        }

        select {
            color: #fff;
            font-size: 14px;
            text-align: right;
            background: transparent;
            border: transparent;
            padding: 5px 0;
            margin-bottom: 0;
        }
    }

    .current-totals {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
        padding: 5px;
        padding-left: 15px;
        align-items: flex-start;
        border-left: 3px solid transparent;
        background-color: var(--gray-800);

        &:nth-child(3) {
            border-color: #369eea;
        }

        &:nth-child(4) {
            border-color: #e02b2b;
        }

        &:nth-child(5) {
            border-color: #fff;
        }

        h3 {
            margin: 0;
            flex-basis: 100%;
        }

        > div {
            // flex: 1;
            flex-basis: calc((100%) / 3);
        }

        h4,
        p {
            margin: 0;
            line-height: 1.2;
        }
    }
}

.billing-chart-filter {
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;

    select {
        width: unset;
        color: #fff;
        font-size: 18px;
        text-align: right;
        background: transparent;
        border: transparent;
        padding: 0;
        margin-bottom: 0;
        font-weight: 700;
        border: 0;
        margin-left: 5px;
    }
}

// Component: TaskOverview
.task-overview {
    > div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
        padding: 5px;
        padding-left: 15px;
        align-items: flex-start;
        // border-left: 3px solid transparent;
        background-color: var(--gray-800);

        h3 {
            margin: 0;
            flex-basis: 100%;
        }

        > div {
            // flex: 1;
            flex-basis: calc((100%) / 3);
        }

        h4,
        p {
            margin: 0;
            line-height: 1.2;
        }
    }
}

// Component: InputWrapper
.input-wrapper {
    margin-bottom: 20px;

    > div {
        > input,
        > textarea,
        > select {
            display: block;
        }
    }

    border-radius: 8px;
    outline: 2px solid transparent;
    outline-offset: 4px;
    transition: outline-color 0.3s 0.5s;

    &.focused {
        transition: outline-color 0.3s 0.5s;
        outline-color: var(--green-100);
    }
}

// Component: FileInput
.file-input {
    margin-bottom: 20px;

    > div:first-child {
        display: flex;
        align-items: flex-start;
    }

    input {
        flex: 1;
        margin-bottom: 0;
        border-radius: 8px 0 0 8px;
    }

    .btn.btn-outline {
        margin-bottom: 0;
        width: auto;
        margin-left: 2px;
        padding: 16px 8px;
    }

    &.loading .upload-button {
        width: 89px;
        height: 50px;

        .spinner {
            margin: 0;
        }
    }

    small {
        margin-top: -3px;
        display: block;
        margin-bottom: 15px;
        color: var(--text-subdued);
    }

    + small.input-info {
        position: relative;
        top: -17px;
        color: var(--text-subdued);
        line-height: 1.2;
        display: block;
    }
}

// Component: Toasts
.toasts {
    position: fixed;
    top: 60px;
    transition: 0.3s;
    opacity: 0;
    z-index: 200;
    right: -100%;
    max-width: 320px;

    display: flex;
    flex-flow: column;
    align-items: flex-end;
    align-content: flex-end;

    &.show {
        right: 10px;
        opacity: 1;
    }

    > div {
        max-width: calc(100vw - 20px);
        border: 3px solid transparent;
        font-weight: bold;
        text-align: right;
        color: #fff;
        background-color: #13bf6e;
        border-radius: 5px;
        padding: 5px 20px;
        margin-top: 0;
        margin-bottom: 10px;
        // box-shadow: 0px 1px 4px 2px #72727266;
        // min-width: 150px;
        display: flex;
        flex-direction: row;
        padding: 0;

        &.warning {
            background-color: var(--orange-100);
        }

        &.alert {
            background-color: rgb(192, 45, 45);
        }

        span {
            display: block;
            padding: 5px;
        }

        .clear-toast {
            width: 20px;
            height: 20px;
            // align-self: flex-end;
            background-color: transparent;
            border: none;
            padding: 0;
            color: #fff;

            // img {
            //     margin-right: 0;
            //     width: 100%;
            //     height: auto;
            //     display: block;
            // }
        }
    }
}

// Component: Header
.header {
    padding: var(--section-padding);
    padding-top: 6px;
    padding-bottom: 1px;
    // display: flex;
    align-items: center;
    z-index: 12;
    position: relative;
    min-height: 55px;

    position: fixed;
    left: 0;
    top: var(--header-top, 0);
    right: 0;
    background-color: var(--bg-primary);
    border-bottom: 1px solid transparent;
    transition: border-color 0.3s;

    max-width: 500px;
    margin: 0 auto;

    &.header-scrolled {
        border-color: var(--bg-primary-100);

        + div {
            .glossary .letter-index ul,
            .html-input .controls,
            .resource-list .list-header {
                top: 50px;
            }
        }
    }

    + div {
        padding-top: 55px;
    }

    a:not(.contact-thumbnail),
    button {
        appearance: none;
        display: block;
        padding: 3px;
        border: none;
        background: transparent;
        cursor: pointer;

        img {
            display: block;
        }
    }

    .contact-thumbnail {
        margin-top: -2px;
        margin-right: 2px;
    }

    .title {
        flex: 1;
        text-overflow: ellipsis;
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
        margin-right: 5px;

        h1 {
            text-align: left;
            font-weight: 700;
            font-size: 14px;
            // line-height: 1;
            margin: 0;
            margin-left: 8px;
            overflow: hidden;
            text-overflow: ellipsis;

            .hash {
                text-transform: capitalize;
            }
        }
    }

    .title-small {
        display: inline-block;
        margin: 0;
        text-align: left;
        color: var(--text-subdued);

        font-size: 12px;
        line-height: 1;
        width: 100%;
        // margin-left: 8px;

        a {
            padding: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .nav-controls:empty {
        display: none;

        & + .title h1 {
            margin-left: 0;
        }
    }

    .header-back-link {
        border-right: 1px solid var(--bg-primary-100);
        margin-left: -12px;
    }

    .header-profile-link {
        position: relative;

        span {
            font-size: 8px;
            position: absolute;
            bottom: -4px;
            left: 50%;
            transform: translate(-50%, 0);
            line-height: 1;
            text-align: center;
            width: 36px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .controls {
        display: flex;
        align-items: center;
        gap: 5px;

        button {
            position: relative;
        }

        .with-badge {
            position: relative;

            > span {
                color: white;
                font-size: 9px;
                line-height: 1;
                content: '';
                position: absolute;
                top: 0;
                right: -1px;
                background-color: var(--red-200);
                width: 18px;
                height: 18px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                word-break: keep-all;
            }
        }

        .spinner {
            width: 1.5rem;
            height: 1.5rem;
        }

        .upload-notifs {
            position: relative;

            .icon-complete {
                padding: 3px;
                display: block;
                width: 24px;
                height: 24px;
                background: var(--green-100);
                border-radius: 50px;
            }

            .icon-progress {
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 8px;
            }
        }
    }

    // .notifs-drawer {
    //     position: fixed;
    //     left: 0;
    //     top: 0;
    //     z-index: -1;
    //     right: 0;
    //     margin: 0 auto;
    //     max-width: 500px;
    //     background: var(--bg-primary-100);
    // }
}
.adsbygoogle .header + div {
    padding-top: 90px;
}

// Component: SwitchInput
.switch-input {
    display: flex;
    padding: 5px;
    background-color: var(--gray-400);
    align-items: stretch;
    border-radius: 8px;
    border: 1px solid var(--gray-400);

    margin-bottom: 21px;

    label {
        flex: 1;
        margin: 0;
        white-space: pre;

        input {
            appearance: none;
            width: 0;
            height: 0;
            overflow: hidden;
            position: absolute;

            + div {
                padding: 10px;
                text-align: center;
                font-size: 15px;
                line-height: 1.3;
                text-align: center;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                font-family: Mulish;
                font-weight: 700;
                cursor: pointer;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
            }

            &:checked + div {
                background-color: var(--primary-500);
                cursor: unset;
            }
        }

        // background-color: var(--gray-400);
        // &:checked {
        //     background-color: var(--primary-500);
        // }
    }

    &.has-error {
        border-color: var(--red-200);
    }

    + .input-info {
        margin-top: -16px;
        margin-bottom: 21px;
    }
}

// Component: HorizontalScroll
.horizontal-scroll {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: var(--section-padding);

    > * {
        flex-shrink: 0;
    }

    &.flush-margins {
        margin-left: calc(-1 * var(--section-padding));
        margin-right: calc(-1 * var(--section-padding));
    }

    &.gap {
        margin-left: calc(-1 * var(--section-padding));
        margin-right: calc(-1 * var(--section-padding));
    }
}

// Component: Button
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    width: 100%;
    background-color: var(--primary-500);
    color: #fff;
    padding: 18px;
    font-size: 15px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-family: Mulish;
    font-weight: 700;
    border: 1px solid transparent;
    cursor: pointer;
    margin-bottom: 30px;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.2);

    > div {
        text-align: center;
    }

    &:hover {
        background-color: #1685d7;
    }

    &.button-has-info {
        margin-bottom: 0;

        ~ .button-info {
            color: var(--text-subdued);
            text-align: center;
            display: block;
            margin-top: 5px;
            margin-bottom: 30px;
        }
    }

    &.button-gradient {
        background: linear-gradient(
            90deg,
            var(--primary-500) 0%,
            var(--primary-600) 100%
        );
    }

    .spinner {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-width: 3px;
        border-color: var(--gray-1);
        border-top-color: transparent;
        margin: 0;
        margin-left: 10px;
        min-width: 20px;
    }

    .icon {
        margin-right: 10px;
    }

    .icon-with-badge-wrapper {
        position: relative;

        .icon-badge {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--red-200);
            position: absolute;
            right: -3px;
            top: -3px;
        }
    }

    &.button-no-text {
        &:disabled {
            background-color: transparent;
        }

        .icon {
            margin-right: 0;
        }

        &.button-loading {
            .icon {
                display: none;
            }
            .spinner {
                margin-left: 0;
            }
        }
    }

    &.button-hide-text {
        .icon {
            margin-right: 0;
        }
    }

    &.button-link {
        display: inline;
        border: none;
        width: auto;
        background-color: transparent;
        color: var(--primary-500);
        padding: 0;
        font-size: 15px;
        line-height: 1.3;
        text-align: left;
        letter-spacing: unset;
        text-transform: unset;
        font-family: Mulish;
        font-weight: 400;
        cursor: pointer;
        margin-bottom: 0;
        box-shadow: none;

        &.button-dark {
            color: var(--gray-1);
            border-color: transparent;

            &:hover {
                color: var(--gray-1);
            }
        }
    }

    &.button-tiny {
        width: unset;
        width: fit-content;
        margin-bottom: 0;
        font-size: 12px;

        &:not(.button-link) {
            padding: 2px 5px;
        }

        > .icon {
            margin-right: 5px;
        }

        a {
            width: fit-content;
        }

        &.button-small-align-right {
            margin-left: auto;
        }

        &.button-link-inline {
            width: fit-content;
            width: -moz-fit-content;
        }

        &.button-no-text {
            padding: 2px;

            .icon {
                margin-right: 0;
            }
        }

        .spinner {
            width: 10px;
            height: 10px;
            min-width: unset;
        }
    }

    &.button-small {
        width: unset;
        width: fit-content;
        margin-bottom: 0;
        padding: 5px 10px;

        a {
            width: fit-content;
        }

        &.button-small-align-right {
            margin-left: auto;
        }

        &.button-link-inline {
            // display: flex;
            width: fit-content;
            width: -moz-fit-content;
        }

        .spinner {
            width: 15px;
            height: 15px;
            min-width: unset;
        }
    }

    &.button-icon-right {
        display: flex;

        > .icon {
            order: 1;
            margin-left: 10px;
            margin-right: 0;
        }
    }

    &.button-icon-top {
        display: flex;
        flex-direction: column;

        > .icon {
            margin: 0;
        }
    }

    &.button-outline {
        border: 1px solid var(--primary-500);
        background-color: transparent;
        color: var(--primary-500);
        box-shadow: none;

        &:hover {
            color: #fff;
        }
    }

    &.button-full-width {
        width: 100%;
    }

    &.button-inline {
        display: inline-flex;
    }

    &.button-white {
        background-color: #fff;
        box-shadow: none;

        &:hover {
            background-color: #fff;
        }

        &.button-outline {
            border: 1px solid #fff;
            background-color: transparent;
            color: #fff;
        }

        &.button-link {
            border: 1px solid transparent;
            background-color: transparent;
            color: #fff;
        }
    }

    &.button-gold {
        background-color: var(--gold);

        &:hover {
            background-color: var(--gold);
        }

        &.button-outline {
            border: 1px solid var(--gold);
            background-color: transparent;
            color: var(--gold);
        }

        &.button-link {
            border: 1px solid transparent;
            background-color: transparent;
            color: var(--gold);
        }
    }

    &.button-alert {
        background-color: #e02b2b;

        &:hover {
            background-color: #af2222;
        }

        &.button-outline {
            border: 1px solid #e02b2b;
            background-color: transparent;
            color: #e02b2b;
        }

        &.button-link {
            border: 1px solid transparent;
            background-color: transparent;
            color: #e02b2b;
        }
    }

    &.button-success {
        background-color: var(--green-100);

        &:hover {
            background-color: #af2222;
        }

        &.button-outline {
            border: 1px solid var(--green-100);
            background-color: transparent;
            color: var(--green-100);
        }

        &.button-link {
            border: 1px solid transparent;
            background-color: transparent;
            color: var(--green-100);
        }
    }

    &.button-card {
        display: flex;
        flex-direction: column;
        position: relative;

        img {
            margin: 0;
            margin-bottom: 10px;
            display: block;
        }

        .card-tags {
            position: absolute;
            top: 5px;
            right: 0;
        }
    }

    &.button-underline {
        text-decoration: underline;
    }

    &.button-no-underline {
        text-decoration: unset;
    }

    &.button-plain:not(.a) {
        background-color: transparent;
        color: #fff;
        box-shadow: none;
        margin: 0;

        &:hover {
            background-color: transparent;
        }

        &:disabled {
            color: var(--gray-400);

            .icon {
                opacity: 0.5;
            }
        }
    }

    &:disabled,
    &.disabled {
        color: #fff;
        cursor: unset;
        background-color: var(--gray-400);

        &.button-outline {
            opacity: 0.5;
            background-color: transparent;
        }

        .spinner {
            border-color: #fff;
            border-top-color: transparent;
        }
    }
}

// Component: PremiumBanner
.premium-banner {
    padding: 10px;
    border-radius: 7px;
    border: 1px solid var(--gold);
    margin: 5px 0;
}

// Component: Switch
.switch {
    .button {
        width: 50%;
    }
}

// Component: ButtonGroup
.button-group {
    header {
        font-weight: bold;
        margin-bottom: 10px;
    }

    &.button-group-background {
        background: var(--bg-primary-200);
        padding: 10px;
    }

    > div {
        display: grid;
        align-items: end;
    }

    &.button-group-col-2 > div {
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    &.button-group-row > div {
        display: flex;
        justify-content: flex-end;

        .button {
            margin-left: 10px;
        }
    }

    &.button-group-grid > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        grid-gap: 10px;
        align-items: stretch;
    }

    .button {
        margin-bottom: 0;
    }

    &.with-image {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;

        header {
            grid-column: 1 / 3;
        }
    }
}

// Component: BrowserFileInput
.browser-file-input {
    margin-bottom: 21px;

    > ul {
        list-style: none;
        padding: 0 0 10px;
        margin: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;

        li {
            margin: 0;
            padding: 10px;
            background: var(--bg-primary-200);
        }
    }

    > .button {
        margin-bottom: 10px;
    }

    .input-container {
        display: flex;
        position: relative;

        img {
            margin-right: 10px;
            width: 20%;
            height: auto;
            object-fit: cover;
        }

        input {
            flex: 1;
            border-radius: 8px;

            &:focus {
                outline: none;
                border-color: var(--primary-500);
            }
        }

        .paste-handle {
            position: absolute;
            bottom: 5px;
            right: 5px;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            background-color: var(--bg-primary-100);
            border: 1px solid var(--bg-primary-100);
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            small {
                display: none;

                font-size: 7px;
                opacity: 0.5;
                line-height: 1;
                user-select: none;
            }

            &:focus {
                border-color: var(--primary-500);

                small {
                    display: block;
                }
            }
        }

        &.has-error input {
            border-color: var(--red-200);
            // input {
            // }
        }
    }

    + small.input-info {
        position: relative;
        top: -17px;
        color: var(--text-subdued);
        line-height: 1.2;
        display: block;
        margin-bottom: -20px;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    &.browser-file-input-small {
        input[type='file'] {
            padding: 5px;
        }
    }

    &.browser-file-input-button {
        margin: 0;

        input[type='file'],
        ul,
        .button {
            display: none;
        }

        .button-container .button {
            display: flex;
        }
    }
}

// Component: Modal
.modal {
    pointer-events: auto;
    position: absolute;
    width: 100%;
    top: 0;
    max-width: unset;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 99;

    .modal-container {
        z-index: 100;
        position: fixed;
        top: 20px;
        left: 20px;
        right: 20px;
        background-color: var(--bg-primary);
        overflow: auto;
        max-height: calc(100vh - 40px);
        max-width: 500px;
        margin: 0 auto;
        opacity: 0;
        transform: translate(0, -30px);
        transition:
            opacity 0.3s linear,
            transform 0.2s ease-out;

        &.show {
            transform: translate(0, 0);
            opacity: 1;
        }

        &.modal-full {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-width: 500px;
            max-height: unset;
            height: 100%;
            width: 100%;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .modal-content {
                flex: 1;
                overflow: auto;
            }
        }

        .modal-header {
            display: flex;
            align-items: center;
            padding: 10px;

            h4 {
                // padding: 3px 10px 10px;
                flex: 1;
                margin: 0;
            }

            button {
                background-color: transparent;
                padding: 5px;
                font-size: 24px;
                line-height: 1;
                border: none;
                color: #fff;
                align-self: flex-start;
            }
        }

        &.has-title .modal-header {
            // border-bottom: 1px solid var(--bg-primary-200);
        }

        .modal-content {
            padding: 10px;
        }

        .image-modal-body {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                display: block;
                max-width: 100%;
                height: auto;
            }
        }

        .modal-buttons {
            padding: 0 10px 10px;
            justify-content: flex-end;
            display: flex;

            button {
                margin-left: 10px;
            }
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.95);
        pointer-events: none;
        z-index: 99;
    }
}

// Component: FilePickerModal
.file-picker-modal {
    .modal-container {
        bottom: 20px;
        max-height: unset;
        display: flex;
        flex-direction: column;

        .modal-content {
            flex: 1;
            display: flex;
            flex-direction: column;

            .scrollable-content {
                flex: 1 1 1px;
            }
        }
    }

    .grid > * {
        position: relative;

        height: calc((100vw - 30px - 20px - 40px - 20px) / 2 + 7px + 15px);
        max-height: calc(213px + 7px + 15px);
        overflow: hidden;

        img {
            max-height: 213px;
            height: calc((100vw - 30px - 20px - 40px - 20px) / 2);
            margin: 0;
            display: block;
            width: 100%;
            object-fit: cover;
        }

        .checkbox {
            position: absolute;
            top: 0;
            left: -1px;
            z-index: 1;
        }
    }
}

// Component: LiveSearchAddItemsInput
.live-search-add-items-input {
    position: relative;
    margin-bottom: 21px;

    .live-search {
        margin-bottom: 0;
    }

    input {
        margin-bottom: 5px;
        padding-right: 85px;
    }

    &:not(.live-search-add-items-input-no-add) {
        .text-input.with-clear {
            input {
                padding-right: 120px;
            }

            .clear-btn {
                right: 60px;
            }
        }
    }

    + small {
        margin-top: -21px;
    }

    > button[type='button'] {
        height: 46px;
        background-color: var(--input-bg);
        position: absolute;
        top: 2px;
        right: 2px;
        border-radius: 8px;

        * {
            color: var(--primary-500);
        }
    }

    .live-search-add-items-input-added-items {
        margin-top: 5px;
    }
}

// Component: ClientCard
.client {
    display: flex;
    padding: 20px 16px;
    border-bottom: 1px solid #292c35;
    justify-content: space-between;
    flex-wrap: wrap;

    & > :nth-child(2) {
        flex: 1;
        padding-right: 5px;
        word-break: break-all;
    }

    .photo {
        border-radius: 50%;
        background-color: gray;
        width: 60px;
        height: 60px;
        margin-right: 13px;
        line-height: 1;

        &.photo-admin {
            border: 4px solid var(--green-100);
        }

        &.photo-premium {
            border: 4px solid var(--gold);
        }

        &.photo-free {
            border: 4px solid var(--pink-800);
        }

        &.photo-lead {
            border: 4px solid var(--gray-200);
        }
    }

    .name {
        font-weight: 700;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 5px;
    }

    .email {
        line-height: 1;
        margin-bottom: 5px;
    }

    .company {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 5px;
    }

    .tag {
        &.success {
            color: #fff;
        }

        &.alert {
            color: #fff;
        }
    }

    .date {
        font-size: 14px;
        font-weight: 700;
        text-align: right;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;

        &.success {
            color: #369eea;
        }

        &.alert {
            color: #e02b2b;
        }
    }

    .cycle {
        font-weight: 700;
        font-size: 16px;
        text-align: right;
    }

    .owed {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: right;

        &.alert {
            color: #e02b2b;
        }
    }

    .extras {
        flex-basis: 100%;
        margin-top: 5px;
        display: flex;

        > div {
            padding: 0 5px;

            p {
                margin: 0;
                line-height: 1;
            }
        }
    }
}
.billing-overview-list-container {
    .card-layout .content {
        > div:first-child {
            display: flex;
            flex-wrap: wrap;
            > div:nth-child(1) > div {
                padding: 0;
                flex: 1;
            }
            > div:nth-child(2) {
                > div {
                    padding: 0;
                }
                flex: 1;
            }
            > div:nth-child(3) {
                flex: 100%;
            }
        }
        > div:last-child {
            flex: unset;
            text-align: right;
            line-height: 1.2;
        }
    }
    .client {
        border-bottom: none;
    }
}

// Component: InvoicesList
table.invoices-list {
    font-size: 12px;
    line-height: 1.1;
    color: #fff;

    td {
        &.alert {
            color: #e02b2b;
        }

        &.invoice-link {
            text-decoration: underline;
        }
    }
}

// Component: Invoice
.invoice-page {
    color: #fff;
}

// Component: SelectInput
.select-input {
    position: relative;

    select {
        padding: 12px;
        border-radius: 8px;
        width: 100%;
        color: var(--input-color);
        border: none;
        margin-bottom: 21px;
        font-weight: 600;
        font-family: Mulish;
        font-size: 16px;
        line-height: 24px;
        border: 0.5px solid #7b8794;
        background-color: var(--input-bg);
        appearance: none;
        // color: var(--primary-500);

        option {
            // color: var(--input-color);
        }

        &:disabled {
            background-color: var(--input-bg);
            opacity: 0.5;
        }

        &:focus {
            outline: none;
            border-color: var(--primary-500);
        }
    }

    &.has-error {
        select {
            border: 1px solid var(--red-200);
        }
    }

    + small.input-info {
        position: relative;
        top: -17px;
        color: var(--text-subdued);
        line-height: 1.2;
        display: block;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    .chevron-container {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        pointer-events: none;

        &:after {
            content: '';
            position: absolute;
            top: 25px;
            right: 25px;
            width: 2px;
            height: 10px;
            transform: rotate(45deg) translateY(-50%);
            background-color: var(--primary-500);
        }

        &:before {
            content: '';
            position: absolute;
            top: 25px;
            right: 25px;
            width: 2px;
            height: 10px;
            transform: rotate(-45deg) translateY(-50%);
            background-color: var(--primary-500);
        }
    }
}

// Component: TextInput
.text-input {
    position: relative;

    //       &:focus-within {
    //     box-shadow: 0 0 0 2px var(--primary-500);
    // }

    .clear-btn {
        position: absolute;
        top: 25px;
        transform: translateY(-50%);
        right: 0;
        cursor: pointer;
        z-index: 1;
        display: block;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;

        .button {
            line-height: 1;
            color: var(--input-color);

            span {
                vertical-align: middle;
            }

            svg {
                display: block;
                width: 16px;
                height: 16px;
            }

            .icon {
                background-color: var(--bg-primary-100);
                border-radius: 50%;
            }
        }

        img {
            display: block;
        }
    }

    input,
    textarea {
        padding: 12px;
        border-radius: 8px;
        width: 100%;
        color: var(--input-color);
        border: none;
        margin-bottom: 21px;
        font-weight: 600;
        font-family: Mulish;
        font-size: 16px;
        line-height: 24px;
        border: 0.5px solid #7b8794;
        background-color: var(--input-bg);
        appearance: none;
        word-break: initial;
        hyphens: initial;
        display: block;

        &:focus {
            outline: none;
            border-color: var(--primary-500);
        }

        &[type='date'],
        &[type='time'] {
            min-height: 52px;
            color-scheme: dark;
        }

        &:placeholder-shown {
            ~ .clear-btn {
                opacity: 0;
            }
        }

        &:focus,
        &:active,
        &:not(:placeholder-shown) {
            ~ .placeholder-content {
                display: none;
            }
        }

        &::placeholder {
            color: var(--input-color);
            opacity: 0.5;
        }

        &:disabled {
            background-color: var(--input-bg);
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &.with-clear {
        input,
        textarea {
            padding-right: 60px;
        }
    }

    &.has-error {
        input,
        textarea {
            border-color: var(--red-200);
        }
    }

    .placeholder-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        pointer-events: none;
        color: var(--input-color);
        opacity: 0.5;
    }

    + .input-info {
        color: var(--text-subdued);
        line-height: 1.2;
        display: block;

        a {
            color: inherit;
            text-decoration: underline;
        }
        margin-top: -17px;
        margin-bottom: 21px;
    }
}

// Component: ResourceList
.resource-list {
    &.has-table .card-layout {
        padding: 0;
        border: none;
    }

    .list-header {
        z-index: 11;
        position: sticky;
        top: 0;
        box-shadow: 0px 10px 10px -8px #000000;
        background-color: var(--bg-primary);
        display: grid;
        grid-template-columns: auto auto;

        // /* Let the element default to the native behavior */
        // scrollbar-width: auto; /* For Firefox */
        // scrollbar-color: auto; /* For Firefox */

        // /* Prevent applying global custom styles */
        // ::-webkit-scrollbar {
        //     width: auto;
        //     height: auto;
        // }

        .resource-list-selected-items-container {
            display: flex;
            min-height: 22px;
            padding-bottom: 0;
            overflow-y: hidden;
            flex-wrap: nowrap;
            overflow-x: auto;
            gap: 5px;
            align-items: center;
            padding: 0 10px;
            // background: var(--bg-primary-200);
            // margin: 0 15px 5px;
            z-index: 1;
            grid-column: 1 / 3;
            // grid-column: 1 / 3;

            > * {
                flex-shrink: 0;
            }

            > .inline-stack {
                flex: 1;
            }

            .resource-list-selected-item {
                background: var(--primary-500);
                color: inherit;
                font-family: inherit;
                display: block;
                border-radius: 50px;
                display: flex;
                align-items: center;
                font-size: 12px;
                padding: 2px 10px;
                appearance: none;
                border: none;
                display: inline-block;
                cursor: pointer;
                // height: 12px;

                img {
                    width: 16px;
                    height: auto;
                    display: block;
                }
            }
        }

        .resource-list-title {
            grid-column: 1 / 2;
            padding-left: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            align-self: center;

            h2 {
                margin: 0;
            }

            .small-title {
                font-size: 14px;
            }

            .resource-list-subtitle {
                margin-top: 5px;
            }
        }

        .resource-list-new-item-btn {
            grid-column: 2 / 3;
            justify-self: end;
            padding-right: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .resource-list-tab-filters {
            grid-column: 1 / 3;
            overflow: hidden;
        }

        .resource-list-filters {
            grid-column: 1 / 3;

            > div:first-child {
                display: flex;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 10px;
                padding-left: 10px;
                justify-content: flex-end;
            }

            > div:last-child {
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 10px;
                padding-left: 10px;
                display: flex;

                flex-wrap: wrap;

                &:empty {
                    display: none;
                }
            }

            .search-input-container {
                flex: 1;
                position: relative;

                .text-input input {
                    padding-top: 7px;
                    padding-bottom: 7px;
                    margin: 0;

                    ~ .clear-btn {
                        top: 20px;
                    }
                }
            }

            .filter-toggle {
                border: 0.5px solid transparent;
                border-radius: 5px;
                background-color: transparent;
                flex-basis: 40px;
                box-sizing: content-box;
                position: relative;
                padding: 0;

                .filter-badge {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: var(--red-200);
                    position: absolute;
                    right: 8px;
                    top: 4px;
                }
            }

            .sort-toggle {
                // margin-left: 5px;
                background-color: transparent;
                border: 0.5px solid transparent;
                color: #fff;
                flex-basis: 40px;
                box-sizing: content-box;
                display: flex;
                flex-direction: column;
                align-items: center;
                align-self: center;
                justify-content: center;
                height: 100%;
                padding: 0;

                img {
                    // flex: 1;
                }

                small {
                    font-size: smaller;
                    line-height: 1;
                    display: inline-block;
                }
            }

            --arrow-color: var(--bg-primary);
            .sort-popover-content {
                padding: 10px;
                background: var(--bg-primary);
            }

            .actions-btn {
                padding: 0;
                flex-direction: row;
                font-family: inherit;
                text-align: left;
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                cursor: pointer;
                text-transform: inherit;
                appearance: none;
                border: none;
                background: none;

                span {
                    width: 4px;
                    height: 4px;
                    border-radius: 5px;
                    background-color: #fff;
                    display: block;
                    margin-bottom: 3px;
                }
            }
        }

        .resource-list-pagination {
            display: flex;
            align-items: center;
            justify-content: space-between;
            grid-column: 1 / 3;
            padding-bottom: 5px;

            &:empty {
                padding: 0;
            }

            .page-counter {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            button {
                background-color: transparent;
                border: none;
                cursor: pointer;
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 5px;
                padding-bottom: 5px;

                &:disabled {
                    opacity: 0.5;
                }
            }

            .page-counter span:nth-child(2),
            .page-counter span:nth-child(3) {
                opacity: 0.5;
            }
        }

        .resource-list-item-views-toggle {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: flex-end;
            grid-column: 2 / 3;
            padding: 5px 15px;
        }
    }

    &.is-validating .list-header {
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -3px;
            height: 3px;
            background-color: white;
            background: linear-gradient(
                90deg,
                var(--bg-primary-100),
                var(--primary-600),
                var(--bg-primary-100)
            );
            background-size: 200% 100%;
            animation: moveBackground 3s linear infinite;
            // z-index: 10;
        }
    }

    .list-layout {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .table-columns {
        display: flex;
        font-weight: bold;

        > span {
            flex: 1 0;
            text-align: right;
            display: flex;
            flex-direction: column;
            line-height: 1;
            padding: 5px;
            border-right: 1px solid var(--bg-primary-100);

            > span:last-child {
                font-size: 12px;
                color: var(--text-subdued);

                line-height: 1;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    &.has-table .list-layout.list-has-widths {
        overflow-x: auto;

        &.list-loading {
            overflow: hidden;
        }
    }

    .table-row {
        display: flex;

        span {
            flex: 1 0;
            text-align: right;
            padding: 5px;
            line-height: 1;
            border-right: 1px solid var(--bg-primary-100);

            &:last-child {
                border-right: none;
            }
        }
    }

    .list-table-footer {
        display: flex;
        font-weight: bold;

        h4 {
            display: block;
            color: var(--text-subdued);

            margin: 0;
        }

        span {
            flex: 1 0;
            border-right: 1px solid var(--bg-primary-100);

            text-align: right;
            padding: 5px;

            &:last-child {
                border-right: none;
            }
        }
    }

    details {
        margin-bottom: 0;
        margin: 20px 0 0;
        border: 1px solid var(--bg-primary-100);

        &:active,
        &:focus {
            outline: none;
        }

        > div {
            border: none;
            border-top: 1px solid #2a3b5e;
            border-bottom: 1px solid #2a3b5e;
            padding: 10px;

            &:nth-child(2) {
                border-top: none;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        summary {
            padding: 10px 10px;
            margin: 0;
            background-color: var(--bg-primary-100);
            list-style: none;
            user-select: none;

            .clearfix {
                flex: 1;
            }

            .counter {
                text-align: center;
                background-color: var(--red-200);
                padding: 0 10px;
                border-radius: 50px;
                margin-right: 5px;
            }

            img {
                display: block;
                transform: rotate(180deg);
            }

            span {
                display: none;
                user-select: none;
                font-weight: bold;
                margin-right: -5px;
                margin-left: 5px;
                width: 24px;
                height: 24px;
            }

            &:active,
            &:focus {
                outline: none;
            }

            &::-webkit-details-marker {
                display: none;
            }

            .grouping-header {
                align-items: center;
                justify-content: space-between;
                display: flex;
            }

            h4 {
                margin: 0;
                font-size: 16px;
            }
        }

        &[open] summary img {
            display: none;
        }

        &[open] summary span {
            display: block;
        }
    }

    .content {
        width: 100%;
    }

    .load-more-button {
        padding: 0 10px;
    }

    &.search-only {
        .list-header {
            .resource-list-filters > div:last-child {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &:not(.has-results) {
            .list-header {
                box-shadow: none;
            }
        }

        .list-layout {
            border: 1px solid #2a3b5e;
            border-top: none;
        }
    }

    &.minimal {
        .list-header {
            box-shadow: none;

            .page-counter {
                display: none;
            }
        }

        .list-layout {
            padding: 0;
        }

        .card-layout {
            border-top: none;
            border-bottom: none;

            &:first-child {
                padding-top: 0;

                .resource-list-item-default {
                    margin-top: 0;
                }
            }

            &:last-child {
                padding-bottom: 0;

                .resource-list-item-default {
                    margin-bottom: 0;
                }
            }
        }

        .resource-list-new-item-btn {
            padding-right: 0;

            .button {
                width: unset;
                width: fit-content;
                margin-bottom: 0;
                padding: 2px 5px;
                font-size: 12px;

                border: 1px solid var(--primary-500);
                background-color: transparent;
                margin-bottom: 20px;
                color: var(--primary-500);
                box-shadow: none;
                margin-bottom: 0;
            }
        }
    }

    &.flush-spaces {
        .resource-list-selected-items-container {
            margin-left: 0;
            margin-right: 0;
        }

        .list-header .resource-list-filters > div {
            padding-left: 0;
            padding-right: 0;
        }

        .list-layout {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.has-field-errors {
        // border-radius: 8px;
        border: 1px solid var(--red-200);
    }
}

// Component: Grid
.grid {
    display: grid;
    gap: 10px;

    &.grid-cols-2 {
        grid-template-columns: 1fr 1fr;
    }

    &.grid-cols-2-1 {
        grid-template-columns: 2fr 1fr;
    }

    &.grid-cols-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    &.grid-cols-4 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &.grid-cols-5 {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

// Component: InlineStack
.inline-stack {
    display: flex;
    align-items: center;

    &.wrap {
        flex-wrap: wrap;
    }

    &.space-between {
        justify-content: space-between;
    }

    &.items-center {
        align-items: center;
    }

    &.items-start {
        align-items: start;
    }

    &.items-end {
        align-items: end;
    }

    &.items-stretch {
        align-items: stretch;
    }

    &.justify-end {
        justify-content: end;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-start {
        justify-content: start;
    }

    &.gap-tiny {
        gap: 7px;
    }

    &.gap-sm {
        gap: 10px;
    }

    &.gap-md {
        gap: 20px;
    }

    &.gap-lg {
        gap: 30px;
    }

    &.row-gap-tiny {
        row-gap: 7px;
    }

    &.row-gap-sm {
        row-gap: 10px;
    }

    &.row-gap-md {
        row-gap: 20px;
    }

    &.row-gap-lg {
        row-gap: 30px;
    }

    &.col-gap-tiny {
        column-gap: 7px;
    }

    &.col-gap-sm {
        column-gap: 10px;
    }

    &.col-gap-md {
        column-gap: 20px;
    }

    &.col-gap-lg {
        column-gap: 30px;
    }

    &.flush-margins > * {
        margin: 0;
    }

    &.overflow-hidden {
        overflow: hidden;
    }
}

// Component: InlineStackItem
.inline-stack-item {
    &.shrink-0 {
        flex-shrink: 0;
    }

    &.grow-1 {
        flex-grow: 1;
    }

    &.self-end {
        align-self: end;
    }
}

// Component: BlockStack
.block-stack {
    display: flex;
    flex-direction: column;

    &.gap-xxs {
        gap: 2px;
    }

    &.gap-tiny {
        gap: 7px;
    }

    &.gap-sm {
        gap: 10px;
    }

    &.gap-md {
        gap: 20px;
    }

    &.gap-lg {
        gap: 30px;
    }

    &.flush-margins > * {
        margin: 0;
    }

    &.items-center {
        align-items: center;
    }

    &.items-start {
        align-items: start;
    }

    &.items-end {
        align-items: end;
    }
}

// Component: Toggle
.toggle {
    // margin: 20px 0 0;
    border: 1px solid var(--bg-primary-100);
    border-radius: 8px;
    overflow: hidden;

    > .toggle-body-wrapper {
        max-height: 0;
        transition:
            max-height 0.3s ease-out,
            padding 0.3s ease-out;

        &[aria-hidden='true'] > .toggle-body {
            pointer-events: none;
            visibility: hidden;
            transition: visibility 0s 0.3s;
        }
    }

    &.open > .toggle-body-wrapper {
        max-height: var(--max-height, 99999px);
    }

    + .toggle {
        margin-top: var(--section-padding);
    }

    &:active,
    &:focus {
        outline: none;
    }

    > .toggle-body-wrapper > .toggle-body {
        padding: 10px;

        > *:first-child:not(button):not(.premium-banner) {
            border-top: none;
        }

        > *:last-child:not(.toggle):not(button):not(.premium-banner) {
            border-bottom: none;
        }

        > .resource-list:not(.minimal),
        > form > .resource-list:not(.minimal) {
            margin-left: calc(-1 * var(--section-padding));
            margin-right: calc(-1 * var(--section-padding));
        }

        > .resource-list:first-child {
            margin-top: calc(-1 * var(--section-padding));
        }

        > .resource-list:last-child {
            margin-bottom: calc(-1 * var(--section-padding));
        }
    }

    > summary {
        padding: 10px 10px;
        margin: 0;
        background-color: var(--bg-primary-100);
        list-style: none;
        user-select: none;
        border: 1px solid transparent;
        border-radius: 8px;
        cursor: pointer;

        &:active,
        &:focus {
            outline: none;
            border-color: var(--primary-500);
        }

        &::-webkit-details-marker {
            display: none;
        }

        .toggle-header {
            align-items: center;
            display: flex;

            .toggle-header-heading {
                flex: 1;

                h4,
                h2 {
                    font-size: 14px;
                    margin: 0;
                    font-weight: bold;
                }
            }

            .toggle-header-content {
                // flex: 1;
                justify-self: end;
                display: flex;
                align-items: center;
            }

            .toggle-header-icon img {
                display: block;
                width: 100%;
                height: 100%;
                transform: rotate(270deg);
                transition: transform 0.3s ease-out;
            }

            .toggle-header-icon.open img {
                transform: rotate(90deg);
            }

            .counter {
                text-align: center;
                background-color: var(--red-200);
                padding: 0 10px;
                border-radius: 50px;
                margin-right: 5px;
            }

            .tag {
                margin-bottom: 0;
            }
        }

        h4 {
            margin: 0;
            font-size: 16px;
        }
    }

    .open > summary .toggle-header-icon img {
        transform: rotate(-90deg);
    }

    &.toggle-sm > summary {
        padding: 5px 10px;
    }

    &.gold {
        border: 1px solid var(--gold);

        .toggle-header img {
            filter: invert(1);
        }

        > summary {
            background-color: var(--gold);
            color: #000;
        }
    }

    &.plain {
        margin: 0;
        border: none;

        > summary {
            padding: 0;
            background-color: transparent;

            > .toggle-header {
                justify-content: start;
                gap: 2px;

                > .toggle-header-heading {
                    flex: unset;
                }

                .toggle-header-icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}

// Component: FavoriteContacts
.favorite-contacts {
    padding-top: 5px;

    div:empty {
        display: none;
    }
}

// Component: ContactThumbnail
.contact-thumbnail {
    position: relative;

    .contact-thumbnail-edit {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        justify-content: center;
        align-items: center;
        z-index: 1;
        background-color: var(--bg-primary-100);
        border-radius: 50%;
    }

    &:hover .contact-thumbnail-edit {
        display: flex;
    }

    .photo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--gray-400);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        line-height: 1;

        &.photo-premium {
            border: 4px solid var(--gold);
        }

        &.photo-admin {
            border: 4px solid var(--green-100);
        }

        &.photo-free {
            border: 4px solid var(--pink-800);
        }

        &.photo-lead {
            border: 4px solid var(--gray-200);
        }

        img {
            position: absolute;

            width: 100%;
            height: 100%;
        }

        span {
            font-weight: bold;
            color: #000;
            opacity: 0.5;
            font-size: 18px;
            text-align: center;
        }
    }

    &.small-size {
        span {
            font-size: 11px;
        }

        .photo {
            width: 24px;
            height: 24px;
            border-width: 2px;
        }
    }
}

// Component: ContactCard
.contact-card {
    a.h3,
    a.strong {
        color: inherit;
    }

    .accounts-info {
        display: flex;
        align-items: center;
        margin-top: 5px;

        > span {
            margin-right: 15px;

            strong {
                flex-shrink: 0;
                line-height: 1.1;
                margin-left: 5px;
                color: #fff;
                opacity: 0.3;
            }
        }
    }
}
.contact-card-with-details {
    > .popover .popover-childen {
        border: 1px solid var(--primary-600);
        background-color: var(--bg-primary-100);
        padding: 10px;

        &:after {
            border-color: transparent transparent transparent
                var(--arrow-color, var(--bg-primary-100));
        }
    }

    .contact-card {
        cursor: pointer;
    }
}

.popover-childen::after {
}
// .contact-card-with-details > * > .contact-card {
// }

// Component: Swatches
.swatches {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 10px;

        li {
            display: block;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: var(--color);
        }
    }
}

// Component: AdminProductItem
.admin-product-item {
    display: grid;
    grid-template-columns: min-content 70px auto;
    grid-template-areas: 'acronym photo main';

    position: relative;

    .title {
        margin-right: 35px;
    }

    .wishlist-add-container {
        position: absolute;
        top: 0px;
        right: 5px;

        img {
            cursor: pointer;
        }
    }

    .admin-product-item-acronym {
        grid-area: acronym;
        font-size: 12px;
        line-height: 1;
        writing-mode: vertical-rl;
        padding: 0 2px;
    }

    .admin-product-item-img-container {
        grid-area: photo;
        width: 70px;
        height: 70px;
        background: var(--gray-400);
        border-radius: 5px;

        img {
            border-radius: 5px;
        }
    }

    .admin-product-item-info {
        grid-area: main;
        padding-left: 10px;

        .title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .desc {
            font-size: 12px;
            margin-bottom: 10px;
        }

        .reviews-container {
            display: flex;
            align-items: center;
        }
    }
}
.item-view-horizontal .admin-product-item {
    grid-template-columns: 0 28px auto;
    padding: 15px 0;

    .wishlist-add-container {
        display: none;
    }

    .admin-product-item-acronym {
        display: none;
    }

    .admin-product-item-img-container {
        grid-area: photo;
        width: 32px;
        height: 32px;
        background: var(--gray-400);
        border-radius: 5px;

        img {
            border-radius: 5px;
        }
    }

    .admin-product-item-info {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;
        padding-left: 10px;

        > *:not(.title, .item-price) {
            display: none;
        }

        .title {
            margin: 0;
        }
    }
}
.item-view-square .admin-product-item {
    grid-template-columns: 1fr;
    grid-template-areas:
        'acronym'
        'photo'
        'main';

    .admin-product-item-acronym {
        writing-mode: unset;
    }

    .admin-product-item-img-container {
        width: 100%;
        padding-bottom: 100%;
    }

    .admin-product-item-info {
        margin-top: 10px;
        padding: 0;

        .button-group.button-group-row > div {
            flex-direction: column;
            gap: 10px;

            button {
                width: 100%;
            }
        }
    }
}
.item-view-double .admin-product-item {
    grid-template-columns: 1fr;
    grid-template-areas:
        'acronym'
        'photo'
        'main';

    .admin-product-item-acronym {
        writing-mode: unset;
    }

    .admin-product-item-img-container {
        width: 100%;
        padding-bottom: 100%;
    }

    .admin-product-item-info {
        margin-top: 10px;
        padding: 0;
    }

    .product-excerpt {
        display: none;
    }

    .button-group.button-group-row > div {
        flex-direction: column;
        gap: 10px;

        button {
            width: 100%;
        }
    }
}

// Component: ActionItem
.action-item {
    background-color: var(--primary-100);
    margin: 0;
    padding: 20px 20px;
    border-bottom: 1px solid #bfcfe1;
    cursor: pointer;
    font-size: 15px;
    line-height: 1;
    text-align: left;
    letter-spacing: 1.25px;
    font-family: Mulish;
    font-weight: 700;
    user-select: none;
    display: flex;
    align-items: center;
    color: var(--gray-1);

    &.success {
        background-color: var(--green-100);
    }

    &.alert {
        color: var(--red-200);
    }

    &.warning {
        background-color: #b3a808;
    }

    &.primary {
        background-color: var(--primary-500);
    }

    &.gold {
        background-color: var(--gold);
    }
    &.gray {
        background-color: var(--gray-800);
    }

    &:last-child {
        border-color: transparent;
    }

    &:hover {
        background-color: #edf5ff;
    }

    &.disabled {
        cursor: unset;
        filter: grayscale(0.9);
        // background-color: var(--gray-800);
        // opacity: 0.7;
        &:hover {
            background-color: var(--primary-100);
        }
    }

    .spinner {
        display: none;
        width: 20px;
        height: 20px;
        border-width: 3px;
        border-color: var(--gray-1);
        border-top-color: transparent;
        margin: 0;
        margin-left: 10px;
        min-width: 20px;
    }

    &.loading {
        height: 57px;

        .spinner {
            display: inline-block;
        }
    }

    .btn-icon {
        margin-right: 10px;
    }

    .btn-icon-empty {
        margin-right: 10px;
        width: 16px;
        height: 16px;
    }
}

// Component: ActionsDropdown
.actions-dropdown {
    z-index: var(--z-index);
    align-self: start;

    .actions-btn {
        appearance: none;
        border-color: transparent;
        background-color: transparent;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 13px;
        margin-bottom: 0;
        border: none;

        &.active {
            border-color: 1px solid var(--gray-800);
        }

        span {
            width: 4px;
            height: 4px;
            border-radius: 5px;
            background-color: #fff;
            display: block;
            margin-bottom: 3px;
        }
    }

    .actions {
        position: fixed;
        top: var(--top);
        bottom: var(--bottom);
        left: var(--left);
        color: var(--gray-1);
        right: var(--right, unset);
        // display: none;
        box-shadow: 0px 0px 10px #000000;
        z-index: 25;
        width: var(--max-width);

        opacity: 0;
        visibility: hidden;
        pointer-events: none;

        &.open {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;

            + .toggle-overlay {
                opacity: 0.5;
                visibility: visible;
                pointer-events: auto;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: var(--after-top, -10px);
            left: var(--after-left, unset);
            right: -10px;
            width: 0;
            height: 0;
            border-style: solid;
            // Arrow pointing right
            border-width: 10px 0 10px 10px;
            border-color: transparent transparent transparent var(--primary-100);
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    &.other-activator .actions::after {
        // Arrow pointing up
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent var(--primary-100) transparent;
    }

    .toggle-overlay {
        position: fixed;
        z-index: 24;
        background-color: black;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
    }
}

// Component: ListLayout
.list-layout {
    padding: 0 10px;

    &:empty {
        display: none;
    }

    &.file-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5px;
    }

    > .spinner {
        margin-top: 20px;
    }

    &.item-view-double {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        > * {
            border: none;
        }
    }

    &.no-padding {
        padding: 0;
    }
}

// Component: CardLayout
.card-layout {
    display: flex;
    border-top: 1px solid #2a3b5e;
    border-bottom: 1px solid #2a3b5e;
    margin-top: -1px;
    padding: 5px 0;
    justify-content: space-between;
    position: relative;

    // *:empty:not(.toggle-overlay) {
    //     display: none;
    // }

    > a,
    > div.no-href {
        flex: 1;
    }

    > div.clickable {
        cursor: pointer;
    }

    .selectable-wrapper {
        display: grid;
        grid-template-columns: 30px 1fr;
        column-gap: 5px;

        > :first-child {
            margin-left: 5px;
        }

        .selectable-icon-disabled {
            opacity: 0.2;
        }

        > *:not(:first-child) {
            position: relative;

            grid-column: 2 / 3;
        }

        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }

    // &.selectable {
    //     padding: 12px 0;

    //     > .no-href {
    //         display: flex;
    //     }

    //     .content > div {
    //         display: flex;

    //         > div:last-child {
    //             flex: 1;
    //         }
    //     }

    //     img {
    //         flex-basis: 40px;

    //         &.selectable-icon-disabled {
    //             opacity: 0.2;
    //         }
    //     }
    // }

    &.selected img {
        opacity: 1;
    }

    .content {
        display: flex;

        > div {
            flex: 1;

            &:nth-child(2) {
                padding-left: 15px;
            }
        }
    }

    .list {
        margin: 10px 0;
        padding: 10px;
        background: var(--bg-primary-200);

        h3,
        h4,
        h5 {
            font-size: 12px;
            margin: 0 0 5px;
            /* opacity: 1.8; */
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    .details {
        h5 {
            margin: 0;
        }
    }

    h3 {
        margin: 2px 0 2px;
        line-height: 1;
    }

    p,
    .resource-list-item-default {
        margin: 4px 0 4px;
        line-height: 1;

        &.caps {
            text-transform: capitalize;
        }

        &.with-icon {
            display: flex;

            img {
                display: inline-block;
                margin-right: 10px;
            }
        }

        &.with-icon ~ .resource-list-item-default {
            margin-left: calc(10px + 16px);
        }
    }

    a {
        color: #fff;
        overflow-wrap: break-word;
    }

    .alert:not(.tag):not(.message-section):not(.actions li) {
        color: var(--red-200);
    }

    .success:not(.tag):not(.message):not(.message-section):not(.actions li) {
        color: var(--green-100);
    }

    .warning:not(.tag):not(.message-section):not(.actions li) {
        color: #e9dc23;
    }

    .disabled:not(.tag):not(.actions li) {
        color: var(--gray-400);
    }

    &.file {
        display: block;
        margin: 0;
        border: none;
        background-color: var(--bg-primary-200);
        padding: 10px;
        color: #fff;

        .content {
            display: block;

            img {
                margin: 0;
                width: 100%;
            }
        }

        &.selected {
            background-color: var(--primary-500);
        }

        // .actions-dropdown {
        //     position: absolute;
        //     top: 5px;
        //     right: 0px;
        //     background-color: var(--gray-800);
        // }

        .toggle-overlay {
            // top: 6px;
            transform: none;
        }
    }
}

// Component: FilterModalContent
.filter-modal-content {
    .modal-body {
        margin-bottom: 20px;
    }

    select,
    select option {
        text-transform: capitalize;
    }
}

// Component: Filter
.filter {
    display: flex;
    justify-content: flex-end;
    z-index: 20;
    overflow: hidden;

    .toggle-filter {
        padding: 3px;
        z-index: 21;
        appearance: none;
        background: none;
        border: none;
        margin-bottom: 0;
    }

    .drawer {
        transform: translate(105%, 0);
        transition: transform 0.1s ease-in;
        z-index: 20;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        background-color: #3d5a98;
        padding: 20px 10px 0;
        max-width: 500px;
        margin: 0 auto;
        box-shadow: 0px 2px 10px #000000;

        &.show {
            transform: translate(0, 0);
        }

        > .order-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 15px;
        }

        .order-btn {
            appearance: none;
            background: none;
            border: none;
            margin: 0;
            width: 45px;
        }

        // button {
        //     margin-left: 20px;
        //     border-color: transparent;
        //     width: unset;
        //     padding: 5px;
        //     background-color: transparent;
        //     // color: #7b8794;
        // }

        select {
            width: 100%;
            color: #fff;
            font-size: 16px;
            text-align: left;
            background: transparent;
            border: 1px solid #fff;
            padding: 5px 10px;
            font-weight: 700;
            appearance: auto;
            margin-bottom: 0;

            &:nth-child(2) {
                width: calc(100% - 90px);
            }
        }

        > div:last-child {
            select {
                margin-bottom: 20px;
            }
        }
    }
}

// Component: TextSkeleton
.text-skeleton {
    animation: skeletonPulse 2s infinite ease-in-out;
    color: transparent;
    background: rgba(255, 255, 255, 0.3);
    user-select: none;
    border-radius: 50px;
    height: var(--skeleton-height, auto);
}

// Component: BoxSkeleton
.box-skeleton {
    animation: skeletonPulse 2s infinite ease-in-out;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    height: var(--skeleton-height, auto);
}

@keyframes skeletonPulse {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 0.2;
    }
}

// Component: PasswordInput
.password-input {
    position: relative;

    input {
        padding-right: 80px;
    }

    .hide-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 30px;
        background: #fff;
        border: none;
        text-transform: uppercase;
        cursor: pointer;
        color: var(--primary-500);
        background-color: var(--input-bg);
        width: 65px;
    }

    + small.input-info {
        line-height: 1.2;
        display: block;
        color: var(--text-subdued);

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    + .input-info {
        margin-top: -17px;
        margin-bottom: 21px;
    }
}

// Component: DragArea
.drag-area {
    .dragged-over {
        border-top: 2px dashed var(--primary-500);
    }

    [draggable] {
        &.pressed {
            // touch-action: none;
        }

        &.dragged {
            opacity: 0.5;
        }
    }
}

// Component: ImportProductItem
.import-product-item {
    display: grid;
    grid-template-columns: 40px auto;
    gap: 10px;
}

// Component: FilePickeInput
.file-picker-input {
    border-radius: 8px;
    padding: 10px;
    background: var(--bg-primary-100);
    border: 1px solid var(--bg-primary-100);

    img[alt='preview'],
    .img-placeholder {
        width: 50px;
        height: 50px;
        object-fit: cover;
        background-color: var(--gray-400);
        flex-basis: 50px;
        flex-shrink: 0;
    }

    .img-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.has-error {
        border-color: var(--red-200);
    }
}

// Component: AddInputGroupsInput
.add-input-groups-input {
    background: var(--bg-primary-100);
    padding: 10px 10px 10px;
    border-radius: 8px;
}

// Component: AddItemsInput
.add-items-input {
    position: relative;

    input {
        padding-right: 50px;
        margin-bottom: 20px;
    }

    .add-more-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 30px;
        border: none;
        text-transform: uppercase;
        cursor: pointer;
        background-color: var(--input-bg);
        color: var(--primary-500);
        width: 65px;
    }

    .extra-form {
        margin-bottom: 20px;
        margin-top: -25px;
        background: var(--bg-primary-100);
        padding: 30px 10px 10px;
        border-radius: 0 0 8px 8px;

        .added-items .added-item {
            border: 1px solid var(--gray-800);
            padding: 10px;
            margin-bottom: 20px;
            position: relative;
        }
    }
}

// Component: CommentItem
.comment-item {
    &.reply {
        margin-left: 20px;
    }

    // border-radius: 8px;
    outline: 2px solid transparent;
    outline-offset: 4px;
    transition: outline-color 0.3s 0.5s;

    &.focused {
        transition: outline-color 0.3s 0.5s;
        outline-color: var(--green-100);
    }
}
// Component: ScrollTopButton
.scroll-top-button {
    position: fixed;
    bottom: 100px;
    right: 20px;
    z-index: 100;
    background-color: var(--bg-primary-100);
    border-radius: 50%;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    pointer-events: none;
    cursor: pointer;

    &.show {
        transition: opacity 0.5s ease-in;
        opacity: 1;
        pointer-events: all;
    }

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

// Component: TreeInput
.tree-input {
    &.has-error .text-input input {
        border-color: var(--red-200);
    }

    .drag-handle {
        cursor: grab;
        [alt='drag'] {
            pointer-events: none;
        }
    }

    [data-dropzone] {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    // .item-title {
    //     font-weight: 500;
    //     margin-bottom: 4px;
    // }

    // .item-details {
    //     font-size: 0.9em;
    //     color: var(--text-subdued);
    // }

    // .item-field {
    //     display: inline-block;
    //     margin-right: 12px;

    //     .field-label {
    //         font-weight: 500;
    //         margin-right: 4px;
    //     }

    //     .field-value {
    //         color: var(--text-subdued);
    //     }
    // }
}

// Component: BorderWrapper
.border-wrapper {
    border: 1px solid var(--bg-primary-100);
    border-radius: 8px;
    padding: 20px;
    position: relative;

    > .border-content-bottom {
        position: absolute;
        bottom: 0;
        transform: translate(-50%, 50%);
        display: inline-block;
        left: 50%;
        right: 0;
        height: 20px;
        background: var(--bg-primary);
        line-height: 1;
        text-align: center;
    }
}

// Component: Terms
.terms {
    margin-top: 20px;
    margin-bottom: 100px;
    text-align: center;
    line-height: 1;
    color: var(--text-subdued);
    padding: 0 5px;

    a {
        color: inherit;
    }
}

// Component: NavBar
.nav-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: #3d5a98;
    z-index: 50;
    box-shadow: 0px -2px 10px #000000;

    nav {
        height: 100%;
        position: relative;
        max-width: 500px;
        margin: 0 auto;

        .button-surround {
            z-index: -1;
            position: absolute;
            top: -16px;
            height: 100px;
            width: 80px;
            background: #3d5a98;
            transform: translate(-50%, 0);
            left: 50%;
            border-radius: 50%;
            box-shadow: 0px -2px 10px #000000;
            // z-index: 0;
        }

        ul {
            height: 100%;
            list-style: none;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 0;
            padding: 0 5px;
            background-color: #3d5a98;

            li {
                margin: 0;
                padding: 5px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                a {
                    color: #fff;
                }

                img {
                    width: 30px;
                    height: auto;
                    margin: 0 auto;
                    display: block;
                }

                span {
                    font-size: 9px;
                    text-transform: uppercase;
                    text-align: center;
                    display: block;
                    line-height: 1;
                    margin-top: 6px;

                    &.active {
                        color: var(--primary-500);
                    }
                }

                &.clearfix {
                    margin-bottom: 16px;
                    width: 65px;
                    height: 65px;
                    border-radius: 50%;
                    // background: red;
                }

                &.placeholder-item {
                    width: 40px;
                }
            }
        }

        .logo {
            position: fixed;
            width: 68px;
            height: 68px;
            z-index: 51;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            appearance: none;
            border: none;
            z-index: 51;
        }
    }
}

// Component: Chat
.chat {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    ul {
        list-style: none;
        margin: 0;
        overflow: auto;
        height: calc(100%);
        padding: 0;
        padding-bottom: 70px;
        scroll-behavior: smooth;

        li {
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 10px;
            }

            .chat-msg-avatar-container {
                width: 24px;
                margin-top: calc((20px + 1rem - 24px) / 2);
            }

            .chat-msg-content {
                min-height: calc(20px + 1rem);

                &:has(.chat-msg-content-reply.chat-type-image) {
                    width: 100%;
                }

                pre {
                    margin: 0;
                }

                .chat-msg-content-original {
                    background-color: var(--bg-primary-200);
                    padding: 10px;
                    border-radius: 8px;
                    color: #fff;
                    font-size: 14px;
                    line-height: 1.2;
                    position: relative;
                }

                .chat-msg-content-reply {
                    border-radius: 8px;
                    background-color: var(--bg-primary-200);
                    opacity: 0.5;
                    padding: 10px;

                    margin-bottom: -5px;
                }

                &.chat-type-image {
                    width: 100%;
                }

                &.chat-msg-own {
                    margin-left: calc(24px + 7px + 30px);

                    .chat-msg-content-original {
                        background-color: var(--primary-500);
                        position: relative;
                    }
                }

                &.chat-msg-other {
                    margin-right: 30px;
                }

                .chat-msg-content-reply.chat-type-image,
                &.chat-type-image .chat-msg-content-original {
                    background-color: transparent;
                    padding: 0;
                    width: 100%;

                    > img {
                        background-color: var(--bg-primary-200);
                        border-radius: 8px;
                        overflow: hidden;
                        display: block;
                        height: auto;
                        width: 100%;
                    }
                }
            }

            // &.chat-msg-own {
            //     .chat-reply-msg {
            //         display: flex;
            //         justify-content: end;
            //     }
            // }
        }
    }

    .chat-controls {
        background-color: var(--bg-primary);
        padding: var(--section-padding);
        padding-top: 0;
        max-width: 500px;
        margin: 0 auto;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        .chat-reply {
            background-color: var(--bg-primary-200);
            padding: 10px;
            max-height: 100px;
            overflow: hidden;
            user-select: none;

            address {
                margin-bottom: 10px;
            }

            img:not(.icon) {
                display: block;
                height: auto;
                max-width: 50%;
                border-radius: 8px;
                overflow: hidden;
            }

            button {
                align-self: start;
            }
        }

        textarea {
            margin-bottom: 0;
            display: block;
        }
    }
}

// Component: Checkbox
.checkbox {
    position: relative;

    input[type='checkbox'] {
        z-index: -1;
        left: 0;
        position: absolute;
        width: 14px;
        height: 16px;
        top: 0;

        &:disabled ~ * {
            opacity: 0.5;
        }
    }

    label {
        padding-left: 35px;
        display: inline-block;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: unset;
        text-align: left;
        text-transform: none;
        color: #fff;
        line-height: 1;
        z-index: 2;
        cursor: pointer;
        user-select: none;
        margin: 3px 0;
    }

    &:before {
        background-image: url('./common/srcAssets/square.svg');
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 24px;
        width: 24px;
        background-repeat: no-repeat;
        z-index: 1;
        background-color: var(--bg-primary);
        pointer-events: none;
    }

    &.checked {
        &:before {
            background-image: url('./common/srcAssets/check-square-active.svg');
            left: 1px;
        }
    }

    small.info {
        display: block;
        color: var(--text-subdued);
        // line-height: 1.2;
        padding-left: 35px;
        margin-top: 5px;
    }

    &.checkbox-small {
        input[type='checkbox'] {
            width: 8px;
            height: 10px;

            // &.checked {
            //     &:before {
            //         background-image: url('./common/srcAssets/check-square-active.svg');
            //         left: 1px;
            //     }
            // }
        }

        label {
            padding-left: 15px;
            font-size: 12px;
            margin: 3px 0;
        }

        &:before {
            top: 3px;
            height: 12px;
            width: 12px;
            background-size: 12px;
        }
    }

    &.has-error:before {
        border-radius: 4px;
        border: 1px solid var(--red-200);
    }
}

// Component: MainButton
.main-button {
    position: fixed;
    /* bottom: 0; */
    width: 68px;
    height: 68px;
    z-index: 52;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 5px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    appearance: none;
    border: none;

    background-color: #3d5a98;
    color: var(--gray-1);

    cursor: pointer;

    &:disabled {
        color: #fff;
        cursor: unset;
    }

    .spinner {
        margin: 0;
        position: relative;
        top: -12px;
    }

    img {
        width: 45px;
        height: 45px;
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translate(-50%, -50%);
        background-color: var(--pink-300);
        border-radius: 50%;
    }

    &.main-button-save img,
    &.main-button-edit img {
        background-color: var(--primary-500);
        padding: 6px;
    }

    &:disabled img {
        background-color: var(--gray-400);
    }

    span {
        color: #fff;
        font-size: 9px;
        text-transform: uppercase;
        text-align: center;
        display: block;
        line-height: 1;
        margin-top: 6px;
        margin-bottom: 10px;
        word-wrap: break-word;
    }

    a span {
        margin-bottom: 12px;
    }
}

// Component: Divider
.divider {
    height: 1px;
    background-color: #2a3b5e;

    &.divider-light {
        background-color: #7b8794;
    }
}

// Component: Break
.break {
    height: 10px;
}

// Layout: Admin
.admin-layout {
    color: #fff;
    // font-size: 14px;
    padding-top: 10px;
    padding-bottom: 100px;
    padding-left: 0;
    padding-right: 0;
    flex: 1;

    > section:not(.section-container),
    .section:not(.section-container),
    > *:not(section) > section:not(.spinner):not(.section-container) {
        margin-bottom: 24px;
        // padding-left: 10px;
        // padding-right: 10px;

        &.hide {
            visibility: hidden;
            height: 1px;
            width: 1px;
            overflow: hidden;
            margin-bottom: 0;
            position: absolute;
        }

        &.info-details {
            // background-color: var(--bg-primary-200);
            padding: 10px 10px;
            margin-bottom: 20px;

            > p {
                margin-bottom: 5px;
            }
        }

        .resource-list-new {
            header > *:not(.tab-filters),
            > .list-layout {
                padding-left: 0;
                padding-right: 0;
            }
            .tab-filters button:first-child {
                margin-left: 0;
            }
            .tab-filters button:last-child {
                margin-right: 0;
            }
        }

        > .section-card {
            background-color: var(--bg-primary-200);

            > h2 {
                font-size: 14px;
            }

            > h2,
            > h3 {
                padding: 5px 10px;
                margin: 0;
                background-color: var(--gray-400);
            }

            > div {
                padding: 10px;
            }

            .resource-list,
            .resource-list-new {
                .list-header,
                .list-footer {
                    background-color: var(--bg-primary-200);
                }
            }
        }
    }

    h1 {
        font-size: 24px;
        overflow-wrap: break-word;
        text-align: center;
        margin-top: 0;
    }

    .info-details p,
    .info-details pre {
        margin: 0;
    }

    .info-details h3 {
        margin-top: 20px;
        margin-bottom: 0;
    }

    .max-width-100 {
        max-width: 100%;
    }

    .btn {
        margin-bottom: 10px;
    }

    header.info-header {
        position: relative;

        h1,
        h2 {
            flex: 1;
            margin: 0;
            line-height: 1;
        }

        p {
            margin-bottom: 5px;
        }
    }

    .btn {
        margin-bottom: 10px;
    }

    .link-btn {
        color: var(--primary-500);
        text-decoration: none;
    }

    .social-post-img,
    .task-attachment-img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 20px auto;
    }

    .caps {
        text-transform: capitalize;
    }

    .alert:not(.tag):not(.actions li) {
        color: var(--red-200);
    }

    .success:not(.tag):not(.message):not(.actions li) {
        color: var(--green-100);
    }

    .primary:not(.tag):not(.actions li) {
        color: var(--primary-100);
    }

    .warning:not(.tag):not(.actions li) {
        color: #e9dc23;
    }

    .disabled {
        color: var(--gray-400);
    }

    .error.alert {
        color: #fff;
    }

    .error.alert:not(.tag):not(.message-section) {
        color: #fff;
    }

    small.notes {
        display: block;
        line-height: 1.2;
        margin-bottom: 10px;
        color: var(--text-subdued);
    }
}

.page-content img {
    max-width: 100%;
    margin: 0 auto;
    height: auto;
}

// // Page: Home
// .home-page {
//     section:not(.message-section) {
//         padding-bottom: 0;
//         margin-bottom: 0;

//         > .toggle
//             > .toggle-body
//             > .resource-list
//             > .list-layout
//             > .card-layout:first-child {
//             border-top: none;
//         }

//         > .toggle
//             > .toggle-body
//             > .resource-list
//             > .list-layout
//             > .card-layout:last-child {
//             border-bottom: none;
//         }
//     }
// }

// Page: NotFound
.not-found-page {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 160px;
    flex-direction: column;

    span {
        font-size: 60px;
    }

    h1 {
        font-weight: bold;
        margin-top: 20px;
        font-size: 20px;
    }
}

.admin-layout .card-layout {
    .email,
    .subtitle {
        font-size: 12px;
        color: var(--text-subdued);
    }

    .full-name,
    .title {
        font-size: 18px;
    }

    .with-image {
        display: flex;

        img {
            margin-right: 10px;
            border-radius: 5px;
        }
    }

    .with-tag {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        .tag {
            margin: 0;
        }
    }

    .task-item-title {
        display: flex;
        justify-content: space-between;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        gap: 5px;

        h3 {
            // flex: 1 1 0;
        }

        span {
            align-self: flex-start;
        }

        > div {
            display: flex;
            flex-direction: column;
        }

        > div:first-child {
            flex: 1;
        }

        > div:last-child {
            align-items: flex-end;
        }
    }

    .task-item-info {
        display: flex;
        gap: 5px;
        align-items: center;

        .tag {
            margin: 0;
        }
    }

    .icon-with-badge {
        position: relative;

        img {
            display: inline-block;
            margin-top: 5px;
        }

        .icon-badge {
            color: white;
            font-size: 10px;
            line-height: 1;
            content: '';
            position: absolute;
            top: -0px;
            right: -1px;
            background-color: var(--red-200);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .task-item-creator {
        font-size: 12px;
        color: var(--text-subdued);
    }

    .task-item-description {
        font-size: 12px;
        color: var(--text-subdued);

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        line-height: 1.1;
    }

    .task-item-button {
        z-index: 1;
    }
}

.profile-card {
    display: flex;
    align-items: center;

    &.has-link {
        cursor: pointer;
    }

    .img-container {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--gray-400);
        border-radius: 50%;
    }

    .info-container {
        padding-left: 10px;
        overflow: hidden;

        > * {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.img-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    &.img-grid-1 {
        grid-template-columns: 1fr;
    }

    &.img-grid-2 {
        grid-template-columns: 1fr 1fr;
    }
}

.sign-container {
    margin-bottom: 20px;
    background-color: rgb(248, 248, 248);

    .sign-canvas {
        background-color: #fff;
        display: block;
        margin: 0 auto;
    }
}

.product-list-image {
    object-fit: cover;
}

.tab-filters,
.tabs {
    // padding-top: 5px;
    // padding-bottom: 10px;
    // overflow: auto;
    // white-space: nowrap;

    @media (max-width: 500px) {
        // padding-left: 3000px;
        // padding-right: 3000px;
        // margin-left: -3000px;
        // margin-right: -3000px;
    }

    button {
        border: none;
        background-color: var(--bg-primary-100);
        color: #ffffff;
        padding: 5px 10px;
        border-radius: 50px;
        // margin-right: 10px;
        font-family: inherit;
        text-transform: capitalize;
        cursor: pointer;

        &:first-child {
            // margin-left: 10px;
        }

        &:last-child {
            // margin-right: 10px;
        }

        &.active {
            background-color: var(--primary-500);
        }

        img {
            vertical-align: middle;
            margin-right: 6px;
            margin-top: -3px;
        }
    }
}

.company-info-toggle-thumbnails .inline-stack > * {
    margin-left: -8px;
}

.vendor-monthly-earn-container {
    display: flex;
    margin-bottom: 10px;

    > div {
        flex: 1;

        h4 {
            margin: 0;
            font-weight: 400;
        }

        > div {
            font-size: 16px;
            font-weight: 700;
        }
    }
}

// Component: PageNav
.page-nav {
    position: fixed;
    bottom: 80px;
    background-color: #3d5a98;
    left: 0;
    right: 0;
    box-shadow: 0px -2px 10px #000000;
    z-index: 50;

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 500px;
        margin: 0 auto;
    }

    button {
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background: transparent;
        font-family: inherit;
        cursor: pointer;
        color: #fff;
        font-size: 30px;
        padding: 2px 20px;

        &:disabled {
            opacity: 0.3;
        }
    }

    .next-content {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
}

.bulk-select-toggle {
    border: 0.5px solid transparent;
    border-radius: 5px;
    background-color: transparent;
    flex-basis: 40px;
    box-sizing: content-box;

    + .popover > div:not(.popover-childen):not(.popover-overlay) {
        width: 30px;
        height: 100%;

        > button.actions-btn.bulk-select-actions {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
}

.filter-toggle {
    border: 0.5px solid transparent;
    border-radius: 5px;
    background-color: transparent;
    flex-basis: 40px;
    box-sizing: content-box;
}

.password-notes-details {
    pre {
        padding: 10px;
        margin: 0;
    }
}
.donations {
    img {
        width: 100%;
    }
}

.donation-page {
    .switch-input label input + div {
        padding: 18px 6px;
    }

    input[value='7'] + div {
        position: relative;

        &:after {
            font-size: 12px;
            content: 'Most Popular';
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translate(-10px, 10px);
            background-color: var(--gold);
            letter-spacing: normal;
            font-weight: normal;
            text-transform: none;
            padding: 2px;
            line-height: 1;
        }
    }

    .button {
        margin-top: 40px;
    }
}

.contact-thumbnail-empty {
    width: 40px;
    height: 100%;
}

@keyframes moveBackground {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

@keyframes loadingSpin {
    100% {
        transform: rotate(360deg);
    }
}

::view-transition-old(menu) {
    animation-duration: 0.5s;
    animation-name: slide-right;
}

::view-transition-new(menu) {
    animation-duration: 0.5s;
    animation-name: slide-left;
}

@media (min-width: 500px) {
    ::view-transition-old(menu) {
        animation-duration: 0.5s;
        animation-name: slide-down;
    }

    ::view-transition-new(menu) {
        animation-duration: 0.5s;
        animation-name: slide-up;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slide-right {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes slide-left {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-down {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
